import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    NgControl,
    ReactiveFormsModule,
} from '@angular/forms'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbOptionComponent } from '../tb-option/tb-option.component'
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select'
import { TbCompareFn, tbCompareFnDefault, TbInputErrors } from '../../../models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { formListenToTouch } from '@core/global-util'
import { TbSelectValue } from '../../models'
import { isArray } from 'lodash-es'
import { MatOption, MatOptionSelectionChange, MatOptionModule } from '@angular/material/core'
import { TbCheckboxComponent } from '@components-library/tb-checkbox'
import { TbTagComponent } from '@components-library/tb-tags'
import { NgTemplateOutlet } from '@angular/common'
import { TbIconComponent } from '../../../tb-icon/tb-icon.component'
import { MatFormFieldModule } from '@angular/material/form-field'

@UntilDestroy()
@Component({
    selector: 'app-tb-select',
    templateUrl: './tb-select.component.html',
    styleUrls: ['./tb-select.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TbSelectComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        MatFormFieldModule,
        TbIconComponent,
        MatSelectModule,
        ReactiveFormsModule,
        TbTagComponent,
        MatOptionModule,
        NgTemplateOutlet,
        TbCheckboxComponent,
        TbDividerComponent,
    ],
})
export class TbSelectComponent<TValue = TbSelectValue>
    implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit
{
    @Input() hint = ''

    @Input() icon = ''

    @Input() label = ''

    @Input() placeholder = ''

    @Input() value: TValue | null = null

    @Input() shaded = false

    @Input() disabled = false

    @Input() multiple = false

    @Input() forceValidation = false

    @Input() tagIconColor?: string

    @Input()
    errors: TbInputErrors = []

    // Automatic focus on the input after the component was instantiated
    @Input() focusInitial = false

    // By default compareFn compares primitive values. Provide this input if value is an object
    @Input() compareFn: TbCompareFn = tbCompareFnDefault

    @Output() selectionChange = new EventEmitter<TValue>()

    @ViewChild('matSelectComponent') matSelectComponent?: MatSelect
    @ContentChildren(TbOptionComponent) tbOptionComponents?: QueryList<TbOptionComponent<TValue>>

    formControl = new FormControl<TValue | null>(null)
    errorMessage = ''

    isDropdownExpanded = false

    get tagBackgroundColor() {
        return this.shaded ? `bg-newNeutral3` : `bg-newPrimaryLight`
    }

    get tagTextColor() {
        return this.shaded ? 'text-newText' : 'text-newNeutral1'
    }

    get multipleValue(): TValue[] {
        if (isArray(this.formControl?.value)) {
            return this.formControl.value
        }

        if (!!this.formControl.value) {
            return [this.formControl.value]
        }

        return []
    }

    get selectedTbOptionComponent() {
        return this.tbOptionComponents?.find((comp) => comp.value === this.formControl.value)
    }

    get multiSelectedTbOptionComponents(): TbOptionComponent<TValue>[] | undefined {
        return this.tbOptionComponents?.filter((comp) => this.multipleValue.includes(comp.value))
    }

    onChange: (value: TValue) => void = () => {}
    onTouched: () => void = () => {}

    private optionsCheckHistory = new Map<MatOption, number>()

    constructor(
        private injector: Injector,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        const parentControl = this.injector.get(NgControl, {})?.control as FormControl | null

        if (parentControl) {
            this.formControl.setValidators(parentControl.validator || null)
            this.formControl.setAsyncValidators(parentControl.asyncValidator || null)

            formListenToTouch(parentControl)
                .pipe(untilDestroyed(this))
                .subscribe((touched) => {
                    if (touched) {
                        this.touchInput()
                        this.cdr.detectChanges()
                    } else {
                        this.untouchInput()
                    }
                })

            if (this.forceValidation) {
                parentControl.markAsTouched()
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('value' in changes) {
            this.writeValue(this.value)
        }

        if ('disabled' in changes) {
            this.setDisabledState(this.disabled)
        }
    }

    ngAfterViewInit() {
        if (this.focusInitial) {
            this.focusInput()
        }
    }
    onFocusIn() {}

    onFocusOut() {
        this.onTouched()
        this.errorMessage = this.formControl.invalid ? this.getErrorMessage() : ''
    }

    onOpened() {
        this.isDropdownExpanded = true
    }

    onClosed() {
        this.isDropdownExpanded = false
    }

    onSelectionChange(event: MatSelectChange) {
        this.formControl.setValue(event.value)
        this.selectionChange.emit(event.value)
        this.onChange(event.value)
        this.onTouched()
    }

    onRemoveValue(val: TValue) {
        const valuesArray = this.formControl.value as TValue

        if (!isArray(valuesArray)) {
            console.error('Select value must be an array in multiple mode')

            return
        }

        const newValues = valuesArray.filter(
            (oldVal: TbSelectValue) => !this.compareFn(oldVal, val),
        ) as TValue
        this.formControl.setValue(newValues)
        this.selectionChange.emit(newValues)
        this.onChange(newValues)
        this.onTouched()
    }

    touchInput() {
        this.formControl.markAsTouched()
        this.formControl.updateValueAndValidity()
        this.onFocusOut()
    }

    untouchInput() {
        this.formControl.markAsUntouched()
    }

    multipleHasValue(value: TValue): boolean {
        const values = this.formControl.value as TValue | null

        if (!isArray(values)) {
            console.error('Select value must be an array in multiple mode')

            return false
        }

        return values.some((oldValue) => this.compareFn(oldValue, value))
    }

    multipleSortComparatorByLastChecked = (a: MatOption, b: MatOption, options: MatOption[]) => {
        const lastCheckedTimeA = this.optionsCheckHistory.get(a)
        const lastCheckedTimeB = this.optionsCheckHistory.get(b)

        if (lastCheckedTimeA && lastCheckedTimeB) {
            return lastCheckedTimeA - lastCheckedTimeB
        }

        return 0
    }

    onOptionSelectionChange(event: MatOptionSelectionChange) {
        if (event.source.selected) {
            this.optionsCheckHistory.set(event.source, Date.now())
        } else {
            this.optionsCheckHistory.delete(event.source)
        }
    }

    writeValue(value: TValue | null): void {
        this.formControl.setValue(value)
    }

    registerOnChange(fn: (val: TValue) => void): void {
        this.onChange = fn
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable()
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    private getErrorMessage() {
        return this.errors.find((error) => this.formControl.hasError(error.code))?.message || ''
    }

    private focusInput() {
        setTimeout(() => {
            this.matSelectComponent?.focus()
            this.matSelectComponent?.open()
        }, 200)
    }
}
