<form [formGroup]="formGroup">
    @for (field of fields; track field) {
        @if (foldersEntity[field.folder_guid!]; as folder) {
            <div class="flex xsm:flex-row items-center mt-2 xsm:mb-0 mb-4">
                <div class="flex items-center w-[12.25rem] pr-4 shrink-0">
                    <app-field-name
                        [field]="field"
                        [folder]="folder"
                        [showIcon]="true"
                        [iconColor]="folder.color.value"
                    >
                    </app-field-name>
                    @if (field.is_required) {
                        <app-tb-icon
                            icon="asterisk"
                            color="newTextLight"
                            class="icon-xs"
                        ></app-tb-icon>
                    }
                    @if (field.is_on_top) {
                        <app-tb-icon
                            icon="upgrade"
                            color="newTextLight"
                            class="icon-xs"
                        ></app-tb-icon>
                    }
                </div>
                <app-record-fields-input
                    [fieldTypes]="fieldTypes"
                    [field]="field"
                    [records]="records"
                    [fieldValueFormControl]="getFieldFormControl(field.guid)"
                    [inputAppearance]="inputAppearance"
                    [hoverIcons]="hoverIcons"
                    class="relative flex items-center w-full grow"
                ></app-record-fields-input>
            </div>
        }
    }
</form>
