import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    GroupDialogData,
    GroupModalComponent,
} from '@app/views/view-controls/view-group/group-modal/group-modal.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { openViewGroupDialog } from '@core/@ngrx/ui'
import { Field, FieldEntities, Folder, View, ViewTypeCodes } from '@core/models'
import {
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@core/services/store-facade'
import { SortDirection } from '@models/ui/sort.model'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Actions, ofType } from '@ngrx/effects'
import { GroupStorageService } from '@services/local-storage/group-storage.service'
import { ViewErrorType, ViewValidatorService } from '@services/view-validator.service'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { isProd } from '@test/dev-utils'
import { combineLatest, of, switchMap, take } from 'rxjs'
import { ViewGroupService } from 'src/app/views/view-controls/view-group/view-group.service'

@UntilDestroy()
@Component({
    selector: 'app-view-group',
    templateUrl: './view-group.component.html',
    standalone: true,
    imports: [
        TbButtonComponent,
        NgTemplateOutlet,
        TranslocoModule,
        FieldNameComponent,
        AsyncPipe,
        TbIconComponent,
    ],
})
export class ViewGroupComponent implements OnInit {
    groupFieldGuid?: string
    defaultGroupGuid?: string
    groupSortDirection?: SortDirection
    fieldEntities?: FieldEntities
    selectedFolder!: Folder
    selectedView!: View

    isGroupDisabled = false
    isGroupSaved = false
    hasErrors = false
    hasGroupErrors = false

    folderStatus$ = this.folderFacadeService.selectSelectedFolderStatusField$.pipe(
        switchMap((folderStatus) => {
            if (folderStatus) return of(folderStatus)
            return this.folderFacadeService.selectGlobalFolderStatus$
        }),
    )

    hasErrors$ = this.viewValidatorService.errors$.pipe(untilDestroyed(this))

    hasGroupErrors$ = this.viewValidatorService
        .validateViewErrors({
            filter: [ViewErrorType.groupInvalidField],
        })
        .pipe(untilDestroyed(this))

    constructor(
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private groupStorageService: GroupStorageService,
        private groupService: ViewGroupService,
        private modalFlowManagerService: ModalFlowManagerService,
        private viewValidatorService: ViewValidatorService,
        private actions$: Actions,
    ) {
        this.subscribeOnOpenAction()
    }

    get groupField(): Field | undefined {
        if (this.fieldEntities && this.groupFieldGuid) {
            return this.fieldEntities[this.groupFieldGuid]
        }
        return
    }

    get fields(): Field[] | undefined {
        if (this.fieldEntities) {
            return this.groupService.filterAllowedGroupFieldsByType(this.fieldEntities)
        }
        return
    }

    ngOnInit(): void {
        this.hasErrors$
            .pipe(untilDestroyed(this))
            .subscribe((hasErrors) => (this.hasErrors = !!hasErrors))
        this.hasGroupErrors$
            .pipe(untilDestroyed(this))
            .subscribe((hasGroupErrors) => (this.hasGroupErrors = !!hasGroupErrors))

        this.folderFacadeService.selectSelectedFolder$
            .pipe(
                untilDestroyed(this),
                switchMap((folder) => {
                    return combineLatest([
                        of(folder),
                        this.schemaFacadeService.selectSelectedTableSchemaFieldEntitiesFiltered$(
                            folder,
                        ),
                    ])
                }),
            )
            .subscribe(([folder, fields]) => {
                this.selectedFolder = folder
                this.fieldEntities = fields
            })

        combineLatest([
            this.viewFacadeService.selectedView$,
            this.folderStatus$,
            this.groupStorageService.getStore$(),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([view, folderStatus]) => {
                if (!view || !folderStatus) return

                this.selectedView = view
                this.groupFieldGuid = this.groupService.getGroupByView(view)
                this.groupSortDirection = this.groupService.getGroupDirectionByView(view)

                this.isGroupSaved = !!view.group.value

                if (this.selectedView.type_code.value === ViewTypeCodes.BOARD) {
                    this.defaultGroupGuid = folderStatus.guid

                    if (!this.groupFieldGuid.length) {
                        this.groupFieldGuid = this.defaultGroupGuid
                    }
                }

                this.groupStorageService.updateIsSetValue(view.guid)
                this.isGroupDisabled = this.setIsGroupDisabled(this.selectedView.type_code.value)
            })
    }

    subscribeOnOpenAction() {
        this.actions$.pipe(ofType(openViewGroupDialog), untilDestroyed(this)).subscribe(() => {
            this.openDialog()
        })
    }

    getButtonColor(hasErrors: boolean) {
        if (hasErrors) return 'error'

        return this.isGroupSaved ? 'secondary' : 'text'
    }

    getDisabledState(hasErrors: boolean, notHasGroupErrors: boolean) {
        return (hasErrors && notHasGroupErrors) || this.isGroupDisabled
    }

    openDialog() {
        this.modalFlowManagerService
            .openDialog<GroupModalComponent, GroupDialogData>({
                component: GroupModalComponent,
                data: {
                    selectedView: this.selectedView,
                    selectedFolder: this.selectedFolder,
                    fields: this.fields,
                    groupValue: this.groupFieldGuid,
                    defaultGroupValue: this.defaultGroupGuid,
                    groupSortDirection: this.groupSortDirection,
                },
            })
            .pipe(take(1))
    }

    private setIsGroupDisabled(viewType: string) {
        return isProd() && viewType === ViewTypeCodes.TABLE
    }
}
