import { Component, OnInit } from '@angular/core'
import { SortModalComponent } from '@app/views/view-controls/view-sort/sort-modal/sort-modal.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { openViewSortDialog } from '@core/@ngrx/ui'
import { View } from '@models/ui'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { SortStorageService } from '@core/services/local-storage/sort-storage.service'
import {
    FolderFacadeService,
    SchemaFacadeService,
    ViewFacadeService,
} from '@core/services/store-facade'
import { SortObject, SortObjectEntities } from '@core/models/ui/sort.model'
import { Actions, ofType } from '@ngrx/effects'
import { ViewErrorType, ViewValidatorService } from '@services/view-validator.service'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { ViewSortService } from 'src/app/views/view-controls/view-sort/view-sort.service'
import { combineLatest, filter } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { AsyncPipe, NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

@UntilDestroy()
@Component({
    selector: 'app-view-sort',
    templateUrl: './view-sort.component.html',
    standalone: true,
    imports: [
        TbButtonComponent,
        TranslocoModule,
        AsyncPipe,
        FieldNameComponent,
        NgTemplateOutlet,
        TbIconComponent,
    ],
})
export class ViewSortComponent implements OnInit {
    filterCountText!: number
    isSortEntitiesEmpty!: boolean
    sortEntities!: SortObjectEntities
    isViewSortSet = false
    firstSortEntity?: SortObject

    hasErrors = false
    hasSortErrors = false

    readonly fieldEntities$ = this.schemaFacadeService.selectSelectedTableSchemaFieldEntities$
    readonly selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    hasErrors$ = this.viewValidatorService.errors$.pipe(untilDestroyed(this))

    hasSortErrors$ = this.viewValidatorService
        .validateViewErrors({
            filter: [ViewErrorType.sortInvalidField],
        })
        .pipe(untilDestroyed(this))

    isDisabled$ = combineLatest([this.hasErrors$, this.hasSortErrors$]).pipe(
        map(([hasErrors, hasSortErrors]) => {
            return !!hasErrors && !hasSortErrors
        }),
    )

    constructor(
        private sortStorageService: SortStorageService,
        private viewFacadeService: ViewFacadeService,
        private schemaFacadeService: SchemaFacadeService,
        private folderFacadeService: FolderFacadeService,
        private sortService: ViewSortService,
        private modalFlowManagerService: ModalFlowManagerService,
        private viewValidatorService: ViewValidatorService,
        private actions$: Actions,
    ) {
        this.subscribeOnOpenAction()
    }

    ngOnInit() {
        this.hasErrors$
            .pipe(untilDestroyed(this))
            .subscribe((hasErrors) => (this.hasErrors = !!hasErrors))
        this.hasSortErrors$
            .pipe(untilDestroyed(this))
            .subscribe((hasSortErrors) => (this.hasSortErrors = !!hasSortErrors))

        this.openMenuFromColumn()
        combineLatest([this.viewFacadeService.selectedView$, this.sortStorageService.getStore$()])
            .pipe(untilDestroyed(this))
            .subscribe(([view]) => {
                if (!view) return

                this.sortEntities = this.sortService.getSortByView(view)
                this.sortStorageService.updateIsSetValue(view.guid)

                this.isViewSortSet = this.checkWhetherViewSortSet(view)
                this.filterCountText = Object.keys(this.sortEntities).length
                this.isSortEntitiesEmpty = !this.filterCountText
                this.firstSortEntity = this.getFirstSortEntity()
            })
    }

    subscribeOnOpenAction() {
        this.actions$.pipe(ofType(openViewSortDialog), untilDestroyed(this)).subscribe(() => {
            this.openDialog()
        })
    }

    getButtonColor(hasErrors: boolean) {
        if (hasErrors) return 'error'

        return this.isSortEntitiesEmpty ? 'text' : 'secondary'
    }

    openDialog() {
        this.modalFlowManagerService
            .openDialog<SortModalComponent, null>({
                component: SortModalComponent,
            })
            .pipe(take(1))
    }

    private openMenuFromColumn() {
        this.sortService.getSortGuidFromColumn$
            .pipe(untilDestroyed(this), filter(Boolean))
            .subscribe(() => {
                this.openDialog()
            })
    }

    private checkWhetherViewSortSet(view: View) {
        const viewSortEntities = view.sort.value

        if (!viewSortEntities) return false

        return !!Object.keys(JSON.parse(viewSortEntities)).length
    }

    private getFirstSortEntity() {
        if (this.isSortEntitiesEmpty) return

        return this.sortEntities[Object.keys(this.sortEntities)[0]]
    }
}
