<ng-container *transloco="let tAddRecordContent; read: 'add_record_content'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (closeModal)="forceWithValidation()"
        class="w-[37.5rem]"
    >
        <ng-container title>
            <div>{{ tAddRecordContent('create_new_record') }}</div>
        </ng-container>
        <ng-container headerButtons>
            <app-tb-button appearance="icon" icon="remove" (click)="pinDraftRecord()">
                remove
            </app-tb-button>
            <app-tb-button appearance="icon" icon="close" (click)="close()"></app-tb-button>
        </ng-container>
        <app-add-record-settings-section
            [folders]="(folders$ | async)!"
            [schemas]="(schemas$ | async)!"
            [pickedFolders]="availableFolders"
            [selectedSchema]="schema"
            (saveSettings)="saveSettings($event)"
            class="pt-4 block"
        ></app-add-record-settings-section>
        <ng-container [formGroup]="uniqueGroupControl">
            <div class="flex flex-wrap mb-4">
                <app-cell-label [field]="nameField" class="w-full">
                    <!-- TODO: remove valueChange after formControl behaviour will be fixed -->
                    <app-input-cell-container
                        [field]="nameField"
                        [formControl]="uniqueGroupControl.controls['name']"
                        appearance="create_record"
                        (valueChange)="uniqueGroupControl.controls['name'].setValue($event)"
                    ></app-input-cell-container>
                </app-cell-label>
            </div>
            @if (richTextField) {
                <div class="flex flex-wrap mb-4">
                    <app-cell-label [field]="richTextField" class="w-full">
                        <!-- TODO: remove valueChange after formControl behaviour will be fixed -->
                        <app-input-cell-container
                            class="body-1 w-full"
                            [field]="richTextField"
                            [formControl]="uniqueGroupControl.controls['description']"
                            (valueChange)="
                                uniqueGroupControl.controls['description'].setValue($event)
                            "
                        ></app-input-cell-container>
                    </app-cell-label>
                </div>
            }
        </ng-container>
        @if (selectedFolder$ | async; as selectedFolder) {
            @if (fieldTypes$ | async; as fieldTypes) {
                @if (records$ | async; as records) {
                    <app-fields-collection
                        [accordionContent]="accordionContent"
                        [availableFolders]="availableFolders"
                        [form]="formGroupDirective?.form"
                        [recordFieldValues]="recordFieldValues"
                        [records]="records"
                        [excludedFieldTypes]="uniqueFieldTypes"
                        [folders]="folders$ | async"
                        [selectedSchema]="schema"
                        [fieldTypes]="fieldTypes"
                        [selectedFolder]="selectedFolder"
                    >
                    </app-fields-collection>
                }
            }
        }
        <ng-container buttons>
            <div class="flex items-center justify-between mt-6">
                <div class="flex justify-start">
                    <app-tb-icon-toggle-button
                        icon="custom_star_outline"
                        toggledIcon="custom_star_full"
                        (toggle)="addToFavorite($event)"
                    >
                        {{ tAddRecordContent('add_favorite') }}
                    </app-tb-icon-toggle-button>
                </div>
                <div class="flex justify-end gap-1">
                    <app-tb-button (click)="createRecord()">
                        {{ tAddRecordContent('create') }}
                    </app-tb-button>
                    <app-tb-button
                        #trigger="matMenuTrigger"
                        [matMenuTriggerFor]="addTaskMenu"
                        [appearance]="'iconPrimary'"
                        [icon]="trigger.menuOpen ? 'expand_less' : 'expand_more'"
                    >
                    </app-tb-button>
                </div>
                <mat-menu class="mb-2" #addTaskMenu="matMenu" yPosition="above" xPosition="before">
                    <div class="body-1">
                        <div
                            (click)="createAndOpen()"
                            class="py-1 px-2 rounded mb-2 hover:bg-primaryBackground cursor-pointer"
                        >
                            {{ tAddRecordContent('create_and_open') }}
                        </div>
                        <div
                            (click)="createAndStartAnother()"
                            class="py-1 px-2 rounded mb-2 hover:bg-primaryBackground cursor-pointer"
                        >
                            {{ tAddRecordContent('create_and_start_another') }}
                        </div>
                        <div
                            (click)="createAndDuplicate()"
                            class="py-1 px-2 rounded hover:bg-primaryBackground cursor-pointer"
                        >
                            {{ tAddRecordContent('create_and_duplicate') }}
                        </div>
                    </div>
                </mat-menu>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
<ng-template
    #accordionContent
    [appTypedTemplate]="contextType"
    let-fieldTypes="fieldTypes"
    let-folder="folder"
    let-availableFolders="availableFolders"
    let-fields="fields"
    let-values="values"
    let-records="records"
    let-form="form"
    let-groupName="groupName"
>
    <app-record-fields
        [fieldTypes]="fieldTypes"
        [folders]="availableFolders ?? [folder]"
        [fields]="fields"
        [values]="values"
        [records]="records"
        [formDirectiveGroup]="form"
        [groupName]="groupName"
    ></app-record-fields>
</ng-template>
