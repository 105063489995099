import { Component, Inject, OnInit } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { FolderService } from '@app/feature/header/folder-controls/folder.service'
import { AccessSelectorComponent } from '@app/shared/access-selector/access-selector.component'
import { ColorPickerComponent } from '@app/shared/color-picker/color-picker.component'
import { EnvDirective } from '@app/shared/env.directive'
import { IconPickerComponent } from '@app/shared/icon-picker/icon-picker.component'
import { SetType } from '@app/shared/models/picker'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { InitResponseModel } from '@core/models'
import { isFolderGlobal } from '@core/models'
import { CreateFolderData, Folder, FolderTemplate } from '@models/ui'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { TranslocoModule, TranslocoService } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FolderFacadeService } from '@services/store-facade'
import { combineLatest, EMPTY, Observable, of, switchMap } from 'rxjs'
import { take } from 'rxjs/operators'
import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component'

export interface FolderDialogData {
    updateData: FolderDialogUpdateData
    folder: Folder
}

export interface FolderDialogUpdateData {
    name: string
    color: string
    icon: string
}

export interface FolderDialogControls {
    name: FormControl<string | null>
    icon: FormControl<string | null>
    color: FormControl<string | null>
}

@UntilDestroy()
@Component({
    selector: 'app-add-folder',
    templateUrl: './add-workspace.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        TbInputComponent,
        ReactiveFormsModule,
        ColorPickerComponent,
        IconPickerComponent,
        EnvDirective,
        AccessSelectorComponent,
        AdvancedOptionsComponent,
        TbButtonComponent,
    ],
})
export class AddWorkspaceComponent
    extends ModalContainerComponent<FolderDialogData>
    implements OnInit
{
    protected readonly SetType = SetType

    isDirty!: boolean

    icon!: string

    color!: string

    folderGroupControl!: FormGroup<FolderDialogControls>

    defaultColor?: string

    defaultIcon?: string

    isGlobal = false

    constructor(
        private folderService: FolderService,
        private folderFacadeService: FolderFacadeService,
        private translation: TranslocoService,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        @Inject(ModalContainerDataToken) protected data: FolderDialogData,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    ngOnInit() {
        this.folderGroupControl = new FormGroup<FolderDialogControls>({
            name: new FormControl(this.data?.updateData.name ?? '', Validators.required),
            icon: new FormControl(this.data?.updateData.icon ?? ''),
            color: new FormControl(this.data?.updateData.color ?? ''),
        })
        const { icon, color } = this.data?.updateData ?? {}

        if (icon?.length) {
            this.defaultIcon = icon
        }

        if (color?.length) {
            this.defaultColor = color
        }

        if (this.data) {
            this.isGlobal = isFolderGlobal(this.data.folder)
        }

        this.dirtyCheck()
    }

    updateColor(color: string) {
        this.folderGroupControl.controls.color.setValue(color)
        this.color = color
    }

    updateIcon(icon: string | null) {
        if (icon) {
            this.folderGroupControl.controls.icon.setValue(icon)
            this.icon = icon
        }
    }

    hasChanged(): boolean {
        return this.isDirty
    }

    apply() {
        if (this.folderGroupControl.invalid) {
            this.errors = [this.translation.translate('dialog_errors.incorrect_input')]
            return
        }

        if (!this.isDirty) {
            this.errors = [this.translation.translate('dialog_errors.no_changes_save')]
            return
        }

        const dialogData = {
            name: this.folderGroupControl.value.name ?? '',
            icon: this.icon ?? this.defaultIcon,
            color: this.color ?? this.defaultColor,
        }
        this.wrapResponse(
            this.data
                ? this.updateFolder(dialogData, this.data.folder)
                : this.createFolderWithTemplateFields(dialogData),
        )
    }

    delete() {
        if (this.data) this.wrapResponse(this.deleteFolder(this.data.folder))
    }

    private dirtyCheck() {
        dirtyCheck(this.folderGroupControl, of(this.folderGroupControl.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }

    private createFolderWithTemplateFields(data: FolderDialogUpdateData) {
        return combineLatest([
            this.folderFacadeService.selectSelectedFolder$,
            this.folderFacadeService.selectFolderTemplate$,
        ]).pipe(
            take(1),
            switchMap(([selectedFolder, folderTemplate]) => {
                if (!folderTemplate) return EMPTY

                return this.folderService.createFolder(
                    this.createModelData(selectedFolder, folderTemplate, data),
                )
            }),
        )
    }

    private createModelData(
        selectedFolder: Folder,
        folderTemplate: FolderTemplate,
        data: FolderDialogUpdateData,
    ): CreateFolderData {
        const templateData = this.folderService.getNewFolderTemplate(folderTemplate)

        return {
            configuration: this.combineModelConfigField(selectedFolder, data),
            ...templateData,
        }
    }

    private updateFolder(data: FolderDialogUpdateData, folder: Folder) {
        const model = {
            configuration: this.combineModelConfigField(folder, data),
        }

        return this.folderService.updateFolder(folder, model)
    }

    private combineModelConfigField(folder: Folder, data: FolderDialogUpdateData) {
        return {
            name: {
                newValue: data.name,
                cell: folder.name,
            },
            color: {
                newValue: data.color,
                cell: folder.color,
            },
            icon: {
                newValue: data.icon,
                cell: folder.icon,
            },
        }
    }

    private deleteFolder(folder: Folder) {
        return this.folderService.deleteFolder(folder)
    }

    private wrapResponse(folderResponseObservable: Observable<InitResponseModel>) {
        this.wrapResponseObservable<InitResponseModel>(folderResponseObservable).subscribe(
            (data) => {
                if (data.status === 'success') {
                    this.close()
                }
            },
        )
    }
}
