import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, TemplateRef } from '@angular/core'
import { MatSelectChange, MatSelectModule } from '@angular/material/select'
import { InputCellContainerComponent } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import {
    CellContainerComponent,
    NEW_CONTAINER_CELLS,
} from '@app/shared/cell-types/cell-container.component'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { BusinessRecords, Field, FieldType, FieldTypes } from '@core/models'
import { FormControl, ReactiveFormsModule } from '@angular/forms'
import { InputContainerAppearance } from '@models/input'
import { Dictionary } from '@ngrx/entity'
import { isArray } from 'lodash-es'
import { MatOptionModule } from '@angular/material/core'
import { MatFormFieldModule } from '@angular/material/form-field'

@Component({
    selector: 'app-record-fields-input',
    templateUrl: './record-fields-input.component.html',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        ReactiveFormsModule,
        CellContainerComponent,
        TbInputComponent,
        InputCellContainerComponent,
        ReactiveFormsModule,
    ],
})
export class RecordFieldsInputComponent implements OnInit {
    protected readonly FieldTypes = FieldTypes
    @Input() field!: Field
    @Input() fieldTypes!: Dictionary<FieldType>
    @Input() fieldValueFormControl!: FormControl<string | null>
    @Input() records!: Dictionary<BusinessRecords>
    @Input() inputAppearance = InputContainerAppearance.create_record
    @Input() hoverIcons?: TemplateRef<any>

    isFieldNonText!: boolean
    supportNewContainer!: boolean
    linkRecords: BusinessRecords[] = []

    get linkValue() {
        return !isArray(this.fieldValueFormControl.value)
            ? this.fieldValueFormControl.value?.split(',')
            : this.fieldValueFormControl.value
    }

    constructor(@Inject(DOCUMENT) private document: Document) {}

    ngOnInit() {
        this.isFieldNonText = this.isFieldNonTextCheck(this.field.field_type_code)
        this.supportNewContainer = this.hasNewContainerSupport(this.field.field_type_code)

        if (this.field.field_type_code === FieldTypes.LINK) {
            this.linkRecords = this.getLinkRecords()
        }
    }

    cellValueChanged(value: string) {
        this.fieldValueFormControl.setValue(value)
    }

    setLinkValue(event: MatSelectChange) {
        this.cellValueChanged(isArray(event.value) ? event.value.join(',') : event.value)
    }

    private isFieldNonTextCheck(type: FieldTypes | string) {
        return [
            FieldTypes.STATUS,
            FieldTypes.DROPDOWN,
            FieldTypes.PEOPLE,
            FieldTypes.ASSIGNEE,
            FieldTypes.RATING,
            FieldTypes.BOOL,
            FieldTypes.DATE,
            FieldTypes.DATE_RANGE,
            FieldTypes.DATE_TIME,
            FieldTypes.LINK,
        ].includes(type as FieldTypes)
    }

    hasNewContainerSupport(type: FieldTypes | string) {
        return NEW_CONTAINER_CELLS.includes(type as FieldTypes)
    }

    updateOnEnter() {
        ;(this.document.activeElement as HTMLElement).blur()
    }

    // TODO: will be reworked in task #1263
    private getLinkRecords() {
        const schemaGuid = this.field.link_definition?.target_solution_object_type_guid

        return Object.keys(this.records)
            .filter((guid) => this.records[guid]?.schemaGuid === schemaGuid)
            .map((guid) => this.records[guid]!)
    }
}
