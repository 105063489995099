import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { UiStoreModule } from '@core/@ngrx/ui'
import { EffectsModule } from '@ngrx/effects'
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { LogService } from '@services/log.service'
import { isProd } from '@test/dev-utils'
import { AutomationStoreModule } from './automation'
import { CommonStoreModule, dataReset } from './common'
import { ConfigStoreModule } from './config'
import { FieldTypeStoreModule } from './field-type/field-type-store.module'
import { FolderStoreModule } from './folder'
import { GroupStoreModule } from './group'
import { RecordStoreModule } from './record'
import { RoleStoreModule } from './role'
import { SchemaStoreModule } from './schema'
import { SubtaskStoreModule } from './subtask/subtask-store.module'
import { UserStoreModule } from './user/user-store.module'
import { ViewStoreModule } from './view'

function metaReducerDataReset(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (action.type === dataReset.type) {
            console.log('[META] Data reset initiated. Clearing store now...')

            return reducer({ router: state.router }, action)
        }

        return reducer(state, action)
    }
}

const metaReducers: MetaReducer[] = [LogService.logStoreActions, metaReducerDataReset]

const imports = [
    CommonModule,
    StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    CommonStoreModule,
    ConfigStoreModule,
    UserStoreModule,
    FieldTypeStoreModule,
    SchemaStoreModule,
    FolderStoreModule,
    ViewStoreModule,
    RecordStoreModule,
    AutomationStoreModule,
    SubtaskStoreModule,
    GroupStoreModule,
    RoleStoreModule,
    UiStoreModule,
]

if (!isProd()) {
    imports.push(StoreDevtoolsModule.instrument({ connectInZone: true }))
}

@NgModule({
    declarations: [],
    imports,
})
export class RootStoreModule {}
