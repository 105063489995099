import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    Output,
} from '@angular/core'
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms'
import { MatMenuTrigger } from '@angular/material/menu'
import { ExtraData } from '@app/feature/input-cells/input-cell-container/input-cell-container.component'
import { OutlineContainerStyleConfig } from '@components-library/tb-outline-container/tb-outline-container.component'
import { INPUT_CONTAINER } from '@models/input'
import { BusinessRecord, Field } from '@models/ui'

@Component({
    template: '',
})
export abstract class BaseCellComponent implements ControlValueAccessor {
    @Input({ required: true })
    field!: Field

    @Input()
    record?: BusinessRecord

    @Input()
    styleConfig?: OutlineContainerStyleConfig

    @Input()
    disabled = false

    @Input()
    extraData?: ExtraData

    @Input()
    placeholder = ''

    @Input()
    readonly = false

    @Input()
    loading = false

    @Input()
    value: string = ''

    @Output()
    valueChange = new EventEmitter<string>()

    touched = false
    focused = false
    mouseOver = false

    protected readonly inputContainer = inject(INPUT_CONTAINER)

    /**
     * The instance of the parent FormControl, it can be null if we do not use FormControls on FormContainer component
     * */
    protected readonly parentControl = inject(NgControl, { optional: true })?.control

    protected readonly elementRef = inject(ElementRef)
    protected readonly cd = inject(ChangeDetectorRef)

    /**
     * Control Accessor implementation API
     * */
    onChange = (_: any) => {}

    /**
     * Control Accessor implementation API
     * */
    onTouched = () => {}

    /**
     * Control Accessor implementation API
     * */
    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    /**
     * Control Accessor implementation API
     * */
    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    protected makeTouchedAndUnfocused() {
        this.touched = true
        this.focused = false
        this.inputContainer.makeUnfocused()
        this.onTouched()
    }

    protected makeTouched() {
        this.touched = true
        this.onTouched()
    }

    protected makeFocused() {
        this.focused = true
        this.inputContainer.focused = true
    }

    protected makeUnfocused() {
        this.focused = false
        this.inputContainer.makeUnfocused()
    }

    /**
     * Control Accessor implementation API
     * */
    abstract writeValue(value: string): void

    /**
     * Control Accessor implementation API
     * */
    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled
    }

    /**
     * Called when we click on InputContainer(TbOutlineInputComponent)
     * */
    abstract onFocusClick(): void

    abstract onContainerClick(): void

    /**
     * Called to check whether we click in outside of Container or we click on element that inside (for e.g. case when a cell has a Menu)
     * */
    eventIsInsideCell(event: Event) {
        return false
    }

    protected isTargetInsideMenu(menuTrigger: MatMenuTrigger, event: Event) {
        const menuId = menuTrigger.menu?.panelId

        if (!menuId) return false

        const menuElement = document.getElementById(menuId)
        if (!menuElement || !(event.target instanceof HTMLElement)) return false

        return menuElement.contains(event.target)
    }
    /**
     * Called when we click outside the InputContainer
     * */
    abstract onOutsideClick(): void

    /**
     * Called by InputContainer when we call Save/Cancel buttons
     * */
    abstract removeFocus(): void

    /**
     * Called by InputContainer when we call Save/Cancel buttons
     * If forces emit of new value
     * */
    abstract emitValue(): void

    /**
     * Called by InputContainer when we call Save/Cancel buttons
     * If forces restoring to the previously saved value
     * */
    abstract resetValue(): void

    abstract get errorState(): boolean

    get loadingState() {
        return this.loading
    }

    protected mergeValidators(control: FormControl) {
        if (this.parentControl && this.parentControl.validator) {
            control.addValidators(this.parentControl.validator)
        }
    }
}
