import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TbButtonComponent } from '../tb-button/tb-button.component'

import { TbIconComponent } from '../tb-icon/tb-icon.component'
import { MatExpansionModule } from '@angular/material/expansion'

@Component({
    selector: 'app-tb-accordion',
    templateUrl: './tb-accordion.component.html',
    styleUrl: './tb-accordion.component.sass',
    standalone: true,
    imports: [MatExpansionModule, TbIconComponent, TbButtonComponent],
})
export class TbAccordionComponent {
    @Input() expanded = false
    @Input() title!: string
    @Input() background = false
    @Input() description = ''
    @Input() settingsShow = false

    @Output() callAdvanced = new EventEmitter()

    get headerBackgroundDisplaying() {
        return this.background ? 'bg-newNeutral2' : 'hover:bg-newNeutral3'
    }

    get headerPaddingDisplaying() {
        return this.background ? 'p-4' : 'px-1'
    }

    get headerRadiusDisplaying() {
        return this.background ? 'rounded-lg' : 'rounded'
    }

    get bodyBackgroundDisplay() {
        return this.background ? 'bg-newNeutral2' : ''
    }

    get contentBackgroundDisplay() {
        return this.background ? 'bg-newNeutral1' : ''
    }
}
