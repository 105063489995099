<app-modal-layout
    [containerLayout]="containerLayout"
    (closeModal)="forceWithValidation()"
    [showLoader]="showLoader"
    [errors]="errors"
    *transloco="let tShow; read: 'show'"
>
    <ng-container title class="mb-2"> {{ tShow('show_title') }} </ng-container>

    <ng-container subtitle> {{ tShow('show_subtitle') }} </ng-container>

    <div class="h-full mt-4" [formGroup]="showFormGroup">
        <div class="flex justify-between w-full mb-4">
            <span class="text-newTextLight body-2"> {{ tShow('fields') }} </span>
            <app-tb-button appearance="text" (click)="toggleAll()">
                {{ hiddenFields.length ? tShow('show_all') : tShow('hide_all') }}
            </app-tb-button>
        </div>
        <div class="flex flex-col gap-4">
            @if (pinnedGuid.length) {
                <app-customize-view-item
                    class="w-full"
                    [field]="fields[pinnedGuid]"
                    [selectedFolder]="data"
                    [toggleValue]="!!getToggleControl(pinnedGuid).value"
                    [pinned]="true"
                    (toggle)="toggle(pinnedGuid, $event)"
                    (pin)="pin(pinnedGuid, true)"
                ></app-customize-view-item>
                <app-tb-divider></app-tb-divider>
            }
            <ng-container *ngFor="let fieldGuid of commonToggleGuids">
                <app-customize-view-item
                    class="w-full"
                    [field]="fields[fieldGuid]"
                    [selectedFolder]="data"
                    [toggleValue]="!!getToggleControl(fieldGuid).value"
                    [pinned]="false"
                    (toggle)="toggle(fieldGuid, $event)"
                    (pin)="pin(fieldGuid)"
                ></app-customize-view-item>
            </ng-container>
        </div>
    </div>

    <ng-container buttons>
        <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end items-center">
            <app-tb-button
                appearance="outlined"
                (click)="forceWithValidation()"
                class="mr-4 whitespace-nowrap"
            >
                <span class="body-2">{{ tButtons('cancel') }}</span>
            </app-tb-button>
            <div class="flex gap-1">
                <app-tb-button appearance="primary" (click)="preview()">
                    {{ tButtons('preview') }}
                </app-tb-button>
                <app-tb-menu [template]="saveMenu" #tbMenu xPosition="before" yPosition="above">
                    <app-tb-button
                        trigger
                        appearance="iconPrimary"
                        [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                    ></app-tb-button>
                </app-tb-menu>
            </div>
            <ng-template #saveMenu>
                <app-tb-menu-list>
                    <app-tb-menu-list-item (click)="resetToSave()">{{
                        tButtons('reset_to_saved')
                    }}</app-tb-menu-list-item>
                    <app-tb-menu-list-item (click)="resetToDefault()">{{
                        tButtons('reset_to_default')
                    }}</app-tb-menu-list-item>
                    <app-tb-divider></app-tb-divider>
                    <app-tb-menu-list-item (click)="save()">{{
                        tButtons('save')
                    }}</app-tb-menu-list-item>
                </app-tb-menu-list>
            </ng-template>
        </div>
    </ng-container>
</app-modal-layout>
