import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { TbMenuComponent } from '@components-library/tb-menu'
import { SelectObjectOptions, SelectOption } from '@models/response/select-object-options'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { of, Subscription } from 'rxjs'

@UntilDestroy()
@Component({
    selector: 'app-base-select-cell',
    standalone: true,
    imports: [],
    templateUrl: './base-select-cell.component.html',
})
export abstract class BaseSelectCellComponent
    extends BaseCellComponent
    implements OnInit, AfterViewInit
{
    protected selectObject!: SelectObjectOptions

    private unknownOption!: SelectOption

    selectData!: SelectOption[]
    control = new FormControl<string>('', { nonNullable: true })
    selectedOption!: SelectOption

    dirtySubscription?: Subscription
    isHoverIcons = false
    isExtraPadding = true
    isDirty = false

    abstract get selectMenu(): TbMenuComponent

    get errorState() {
        return this.control.invalid && this.touched
    }

    get loadingState() {
        return this.loading
    }

    getOptionClass(guid: string) {
        const color = (this.selectObject[guid] ?? this.unknownOption).color
        return {
            [`bg-${color}`]: color.includes('new'),
        }
    }

    getBorderForOption(guid: string) {
        return {
            'border-2': guid === this.selectedOption.guid,
            'border-newNeutral-4': guid === this.selectedOption.guid,
        }
    }

    ngOnInit() {
        this.selectObject = this.field.select_object_field ?? {}

        this.isHoverIcons = !!this.inputContainer.hoverIcons
        this.isExtraPadding = this.inputContainer.editControls

        this.selectData = this.prepareSelectData()

        this.setInitialValue()
        this.setSelectAndUnknownOption()
        this.setControl()
    }

    ngAfterViewInit() {
        this.selectMenu.stateChanged
            .pipe(untilDestroyed(this))
            .subscribe(() => (this.touched = true))
    }

    writeValue(guid: string) {
        this.value = guid
        this.setSelectValue(this.value)
    }

    setSelectValue(guid: string) {
        this.control.setValue(guid)
        this.cd.detectChanges()
    }

    override eventIsInsideCell(event: MouseEvent) {
        return this.isTargetInsideMenu(this.selectMenu.menuTrigger, event)
    }

    emitValue(): void {
        if (!this.isDirty) return

        this.value = this.control.value

        this.valueChange.emit(this.value)
        this.onChange(this.value)
        this.setCheckOnDirty()
        this.makeTouchedAndUnfocused()
        this.control.markAsUntouched()
    }

    onFocusClick(): void {
        this.makeFocused()
        this.selectMenu.openMenu()
    }

    onOutsideClick(): void {
        if (!this.selectMenu.menuOpen && this.focused) {
            this.makeTouchedAndUnfocused()
            this.inputContainer.resetActive()
            this.inputContainer.focused = false
            this.removeFocus()
            this.emitValue()
        }
    }

    removeFocus(): void {
        this.focused = false
    }

    resetValue(): void {
        this.control.setValue(this.value)
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    onContainerClick(): void {}

    protected abstract setInitialValue(): void

    protected abstract prepareSelectData(): SelectOption[]

    private setSelectAndUnknownOption() {
        this.unknownOption = this.combineUnknownOption()

        this.selectedOption = this.selectObject[this.value]
            ? {
                  ...this.selectObject[this.value],
                  guid: this.value,
              }
            : this.unknownOption
    }

    private setControl() {
        this.control.setValue(this.value)
        this.mergeValidators(this.control)

        this.setCheckOnDirty()

        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.selectedOption = this.selectObject[value]
                ? {
                      guid: value,
                      ...this.selectObject[value],
                  }
                : this.unknownOption
        })
    }

    private combineUnknownOption() {
        return {
            guid: this.value,
            label: 'Unknown',
            color: 'newNeutral4',
        }
    }

    private setCheckOnDirty() {
        this.dirtySubscription?.unsubscribe()

        this.dirtySubscription = dirtyCheck(this.control, of(this.control.value), {
            debounce: 0,
            useBeforeunloadEvent: false,
        })
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => {
                this.isDirty = isDirty
            })
    }
}
