import { Component, Input } from '@angular/core'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { BusinessRecords } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { RecordsService } from '@services/records.service'
import { UiFacadeService } from '@services/store-facade/ui-facade.service'
import { take } from 'rxjs/operators'

@UntilDestroy()
@Component({
    selector: 'app-board-card-menu',
    standalone: true,
    imports: [
        TbDividerComponent,
        TbIconComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        TranslocoModule,
    ],
    templateUrl: './board-card-menu.component.html',
})
export class BoardCardMenuComponent {
    @Input({ required: true }) record!: BusinessRecords

    constructor(
        private recordService: RecordsService,
        private modalFlowManagerService: ModalFlowManagerService,
        private uiFacadeService: UiFacadeService,
    ) {}

    delete() {
        this.uiFacadeService
            .wrapRequestWithViewLoader(this.recordService.deleteRecord(this.record))
            .pipe(take(1), untilDestroyed(this))
            .subscribe()
    }

    duplicate() {
        this.recordService.duplicateRecord(this.record)
    }

    manageFields() {
        this.modalFlowManagerService.openDialog({ component: ManageFieldsComponent })
    }

    openCard() {
        this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: { recordGuid: this.record.guid, isFolder: true },
        })
    }
}
