<app-modal-layout
    *transloco="let tManageFields; read: 'manage_fields'"
    [containerLayout]="containerLayout"
    [showLoader]="showLoader"
    [errors]="errors"
    (closeModal)="forceWithValidation()"
    class="block w-[31.25rem]"
>
    <ng-container title>{{ tManageFields(editMode ? 'manage_field' : 'add_field') }}</ng-container>
    <ng-container subtitle
        ><div class="w-3/4">{{ tManageFields('add_field_subtitle') }}</div></ng-container
    >
    <div [formGroup]="formGroup" class="mt-4">
        <div>
            <div class="flex w-full gap-4">
                @if (data.folder_guid) {
                    <div class="flex flex-col flex-1">
                        <div class="body-1">{{ tManageFields('workspace') }}</div>
                        <app-tb-select
                            class="flex-1 text-nowrap overflow-ellipsis"
                            formControlName="folder"
                        >
                            @for (folder of foldersForMove; track folder) {
                                <app-tb-option
                                    [icon]="folder.icon.value"
                                    [iconColor]="folder.color.value"
                                    [value]="folder.guid"
                                >
                                    {{ folder.name.value }}
                                </app-tb-option>
                            }
                        </app-tb-select>
                    </div>
                }
                @if (availableFieldTypes$ | async; as types) {
                    <div class="flex flex-col flex-1">
                        <div class="body-1">{{ tManageFields('field_type') }}</div>
                        <app-tb-select
                            [value]="data.folder_guid"
                            class="flex-1"
                            formControlName="fieldType"
                        >
                            @for (type of types; track type) {
                                <app-tb-option [icon]="type.icon" [value]="type.guid">
                                    {{ type.name }}
                                </app-tb-option>
                            }
                        </app-tb-select>
                    </div>
                }
            </div>
            <div class="mb-2 mt-n2">
                <app-tb-input
                    class="w-full"
                    [label]="tManageFields('field_name')"
                    [placeholder]="tManageFields('name_placeholder')"
                    formControlName="columnName"
                ></app-tb-input>
            </div>
        </div>
        <div class="w-full flex flex-row justify-start items-center">
            @if (field.field_type_code === FieldTypes.STATUS) {
                <ng-container [ngTemplateOutlet]="content"></ng-container>
            } @else {
                <ng-container
                    *appEnv="'prod'; visible: false"
                    [ngTemplateOutlet]="content"
                ></ng-container>
            }
            <ng-template #content>
                <app-add-field-content
                    (fieldChanged)="changedValue($event)"
                    (valid)="isColumnContentValid = $event"
                    [fieldType]="field.field_type_code"
                    [field]="field"
                ></app-add-field-content>
            </ng-template>
        </div>
        <div class="flex items-start mb-4">
            <app-tb-toggle formControlName="onTop"></app-tb-toggle>
            <div class="mt-1 ml-4">
                <div class="body-1">{{ tManageFields('on_top') }}</div>
                <div class="caption-1 text-newTextLight">
                    {{ tManageFields('on_top_description') }}
                </div>
            </div>
        </div>
        <div class="flex items-start">
            <app-tb-toggle formControlName="required"></app-tb-toggle>
            <div class="mt-1 ml-4">
                <div class="body-1">{{ tManageFields('required_field') }}</div>
                <div class="caption-1 text-newTextLight">
                    {{ tManageFields('required_field_description') }}
                </div>
                @if (isRequired) {
                    <div>
                        <span class="body-1 mb-1">{{ tManageFields('default_value') }}</span>
                        @if (supportNewContainer) {
                            <app-input-cell-container
                                [field]="field"
                                appearance="create_record"
                                formControlName="defaultValue"
                            ></app-input-cell-container>
                        } @else {
                            <!--TODO: convert to form control-->
                            <app-cell-container
                                [field]="field"
                                [guid]="field.guid"
                                [value]="field.default_value ?? ''"
                                [fieldTypes]="(allFieldTypesDictionary$ | async) || {}"
                                (cellValueChanged)="defaultValueChanged($event)"
                            ></app-cell-container>
                        }
                    </div>
                }
            </div>
        </div>
        @if (shareAvailable) {
            <div class="mt-4 flex flex-col">
                <div class="body-2">{{ tManageFields('share_field') }}</div>
                <span class="pb-2 caption-1 text-newTextLight">{{
                    tManageFields('share_field_description')
                }}</span>
                <app-tb-select
                    formControlName="sharedFolders"
                    [placeholder]="tManageFields('share_field_select_placeholder')"
                    [value]="data.shared_with_folder"
                    [multiple]="true"
                    [shaded]="true"
                >
                    @for (folder of foldersForShare; track folder) {
                        <app-tb-option
                            [iconColor]="folder.color.value"
                            [icon]="folder.icon.value"
                            [value]="folder.guid"
                        >
                            {{ folder.name.value }}
                        </app-tb-option>
                    }
                </app-tb-select>
            </div>
        }
        <!--TODO: add accordion-->
    </div>
    <ng-container buttons>
        <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end">
            <div class="flex sm:gap-1 gap-2 flex-1 justify-between items-center">
                <app-tb-button appearance="text" icon="custom_arrow_back" (click)="close()">
                    {{ tButtons('back') }}
                </app-tb-button>
                <div class="flex items-center">
                    <app-tb-button
                        *appOperation="field.operationCode; target: 'delete'; condition: editMode"
                        appearance="text"
                        icon="close"
                        color="secondary"
                        (click)="delete()"
                    >
                        {{ tButtons('delete') }}
                    </app-tb-button>
                    <div class="flex justify-end items-center pl-5 max-sm:px-0">
                        <app-tb-button (click)="saveColumn()" class="mr-1">
                            {{ tButtons('save') }}
                        </app-tb-button>
                        <app-tb-menu
                            #tbMenu
                            [template]="fieldSaveOptions"
                            yPosition="above"
                            xPosition="before"
                        >
                            <app-tb-button
                                trigger
                                [appearance]="'iconPrimary'"
                                [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                            >
                            </app-tb-button>
                        </app-tb-menu>

                        <ng-template #fieldSaveOptions>
                            <app-tb-menu-list>
                                <app-tb-menu-list-item (click)="saveAndDuplicate()">
                                    {{ tManageFields('save_and_duplicate') }}
                                </app-tb-menu-list-item>
                                <app-tb-menu-list-item (click)="saveAndCreateAnotherOne()">
                                    {{ tManageFields('save_and_start') }}
                                </app-tb-menu-list-item>
                            </app-tb-menu-list>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</app-modal-layout>
