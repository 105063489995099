import { Update } from '@ngrx/entity'
import { createAction, props } from '@ngrx/store'
import { Deleted, View } from '../../models'

export const initViews = createAction(
    '[View] Init views',
    props<{ views: View[]; selectedViewGuid?: string }>(),
)

export const viewsAreLoaded = createAction('[View] Views are loaded')

export const setSelectedViewGuid = createAction(
    '[View] Set selected view',
    props<{ selectedViewGuid: string | null }>(),
)

export const addViewsEntities = createAction('[View] Add views', props<{ views: View[] }>())

export const updateViewsEntities = createAction(
    '[View] Update views',
    props<{ views: Update<View>[] }>(),
)

export const deleteViewsEntities = createAction(
    '[View] Delete views',
    props<{ views: Deleted[] }>(),
)
