@if (field) {
    <div class="flex items-center w-full">
        @if (showIcon) {
            <app-tb-icon
                [icon]="getFieldIcon()"
                [color]="iconColor ? 'newNeutral1' : 'newTextLight'"
                [class.bg-newNeutral1]="!iconColor"
                [style.background-color]="iconColor"
                class="mr-2 rounded-md icon-sm p-1"
            >
            </app-tb-icon>
        }
        <app-tb-tooltip-component [tooltip]="getTooltipText()" class="w-full grow">
            @if (showFolderName) {
                <div class="flex items-center max-w-[55%] float-left">
                    <span class="truncate inline-block w-full">{{ field.folder_name!.name }}</span>
                    <span class="w-5%">&nbsp;/&nbsp;</span>
                </div>
            }
            <span
                class="truncate inline-block float-left"
                [style.width]="showFolderName ? '40%' : '100%'"
                >{{ field.name }}</span
            >
        </app-tb-tooltip-component>
    </div>
}
