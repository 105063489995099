<div
    *transloco="let tCardSide; read: 'card_side_content'"
    class="flex flex-col gap-6 justify-center items-center h-full pl-4 pr-6 pt-6"
>
    <div class="body-1 text-newTextLight text-center">
        {{ tCardSide('await_feature') }}<br />
        {{ tCardSide('touches') }}
    </div>
    <img
        alt="rocket"
        [ngSrc]="'../../../assets/img/rocket_placeholder.svg'"
        width="310"
        height="280"
    />
    <div class="body-1 text-newTextLight">{{ tCardSide('watch_for_updates') }}</div>
</div>
