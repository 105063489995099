import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TranslocoModule } from '@ngneat/transloco'
import { UiFacadeService } from '@services/store-facade/ui-facade.service'
import { ViewErrorEntity, ViewErrorType } from '@services/view-validator.service'

@Component({
    selector: 'app-view-invalid-message',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        TbButtonComponent,
        NgOptimizedImage,
        NgTemplateOutlet,
    ],
    templateUrl: './view-invalid-message.component.html',
})
export class ViewInvalidMessageComponent {
    @Input() errors!: ViewErrorEntity[]

    get isFilterError() {
        return this.errors.find((error) => error.type === ViewErrorType.filterInvalidField)
    }

    get isSortError() {
        return this.errors.find((error) => error.type === ViewErrorType.sortInvalidField)
    }

    get isGroupError() {
        return this.errors.find((error) => error.type === ViewErrorType.groupInvalidField)
    }

    constructor(private uiFacadeService: UiFacadeService) {}

    openFiltersDialog() {
        this.uiFacadeService.openViewFilterDialog()
    }

    openSortDialog() {
        this.uiFacadeService.openViewSortDialog()
    }

    openGroupDialog() {
        this.uiFacadeService.openViewGroupDialog()
    }
}
