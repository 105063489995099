import { Injectable } from '@angular/core'
import { AddFieldComponent } from '@app/feature/add-field/add-field.component'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { AddFieldData, Field } from '@models/ui'
import { CommonFacadeService, SchemaFacadeService } from '@services/store-facade'
import { UiFacadeService } from '@services/store-facade/ui-facade.service'
import { EMPTY, finalize } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class ManageFieldsService {
    constructor(
        private modalFlowManagerService: ModalFlowManagerService,
        private confirmationPopupService: ConfirmationDialogService,
        private schemaFacadeService: SchemaFacadeService,
        private uiFacadeService: UiFacadeService,
    ) {}

    addField(data: AddFieldData, modalManagerService?: ModalManagerService) {
        const service = modalManagerService ?? this.modalFlowManagerService
        return service.openDialog<AddFieldComponent, AddFieldData, Field | undefined>({
            component: AddFieldComponent,
            data,
        })
    }

    editField(field: Field) {
        this.openEditFieldDialog(field)
    }

    duplicateField(field: Field) {
        this.openEditFieldDialog({
            ...field,
            name: field.name + ' copy',
            duplicate: true,
        })
    }

    deleteField(guid: string, showLoaderOnConfirm: boolean = false) {
        return this.confirmationPopupService
            .openCommon({
                translations: {
                    title: 'manage_fields.delete_field_conf_title',
                    message: 'manage_fields.delete_field_conf_message',
                },
            })
            .pipe(
                switchMap((status: CommonDialogResultStatus) => {
                    if (status === CommonDialogResultStatus.CONFIRM) {
                        if (showLoaderOnConfirm) {
                            this.uiFacadeService.showViewLoader()
                        }
                        return this.schemaFacadeService.deleteField(guid)
                    }
                    return EMPTY
                }),
                finalize(() => {
                    if (showLoaderOnConfirm) {
                        this.uiFacadeService.hideViewLoader()
                    }
                }),
            )
    }

    private openEditFieldDialog(data: AddFieldData) {
        this.modalFlowManagerService.openDialog<AddFieldComponent, AddFieldData>({
            component: AddFieldComponent,
            data,
        })
    }
}
