import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TbInputComponent } from '@components-library/tb-input/tb-input.component'
import { TbSelectValue } from '@components-library/tb-select/models'
import { FieldEntities, Folder } from '@models/ui'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { FilterTypesContainerComponent } from '@app/views/view-controls/view-filter/filter-types/filter-types-container.component'
import { MatIconModule } from '@angular/material/icon'
import { ReactiveFormsModule } from '@angular/forms'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'
import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-filter-empty-item',
    standalone: true,
    imports: [
        FieldNameComponent,
        FilterTypesContainerComponent,
        MatIconModule,
        ReactiveFormsModule,
        TbOptionComponent,
        TbSelectComponent,
        TranslocoModule,
        TbInputComponent,
    ],
    templateUrl: './filter-empty-item.component.html',
})
export class FilterEmptyItemComponent {
    @Input({ required: true })
    fieldGuids!: string[]

    @Input({ required: true })
    fields!: FieldEntities

    @Input({ required: true })
    selectedFolder!: Folder

    @Output() setFilterItem = new EventEmitter<TbSelectValue>()
}
