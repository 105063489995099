import { Injectable } from '@angular/core'
import { GroupObject } from '@app/views/view-controls'
import { DataStorageService } from '@services/local-storage/view-settings-storage/data-storage.service'
import { ViewSettingsStorageService } from '@core/services/local-storage/view-settings-storage/view-settings-storage.service'

@Injectable({
    providedIn: 'root',
})
export class GroupStorageService extends ViewSettingsStorageService<GroupObject> {
    constructor(localStorageService: DataStorageService) {
        super(localStorageService, 'group')
    }

    get(key: string): GroupObject | null {
        const groupValue = super.get(key)

        if (!groupValue) return null

        if (typeof groupValue !== 'string') return groupValue

        return groupValue.length ? this.safeParse(groupValue) : null
    }

    //TODO: [check-before-prod] should be removed in task #1872
    private safeParse(groupObjectString: string): GroupObject {
        try {
            return JSON.parse(groupObjectString)
        } catch (e) {
            return {
                guid: groupObjectString,
            }
        }
    }
}
