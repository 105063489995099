<div class="inline-block rounded-full shrink-0 overflow-hidden" [class]="avatarClass">
    @if (userUrl) {
        <img
            class="flex-shrink-0 h-full w-full"
            [src]="userUrl"
            alt=""
            onerror="this.src='../../../../assets/img/avatar.png'"
        />
    } @else {
        <div class="rounded-full flex items-center justify-center" [class]="iconBgClass">
            <app-tb-icon
                [class]="small ? 'icon-sm' : 'icon-m'"
                [icon]="unknown ? 'person_off' : 'person'"
                [filled]="true"
                color="newNeutral1"
            ></app-tb-icon>
        </div>
    }
</div>
