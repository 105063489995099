<!-- show ng-content if content is not empty. Otherwise show label text -->

<ng-template #optionTemplate>
    <div class="flex flex-row items-center">
        @if (icon) {
            <app-tb-icon
                [icon]="icon"
                [style.color]="iconColor ?? ''"
                [color]="iconColor || 'newTextLight'"
                matIconPrefix
            ></app-tb-icon>
        }

        <div class="max-w-full flex items-center" #optionContent>
            <ng-content></ng-content>
        </div>

        @if (active) {
            <app-tb-icon
                [icon]="'check'"
                color="newPrimary"
                class="ml-1 mr-0"
                matIconPrefix
            ></app-tb-icon>
        }
    </div>
</ng-template>
