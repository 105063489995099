// Names of store root properties
export enum StoreFeatureNames {
    Automation = 'AUTOMATION',
    Common = 'COMMON',
    Config = 'CONFIG',
    FieldType = 'FIELD_TYPE',
    Folder = 'FOLDER',
    Group = 'GROUP',
    Role = 'ROLE',
    Record = 'RECORD',
    Schema = 'SCHEMA',
    Users = 'USERS',
    View = 'VIEW',
    UI = 'UI',
}
