<div
    *transloco="let tInvalidMessage; read: 'view_invalid_message'"
    class="flex flex-col items-center w-full h-full mt-12"
>
    <img
        ngSrc="../../../../assets/img/Filters_invalid_field.svg"
        width="328"
        height="362"
        [alt]="tInvalidMessage('img_alt')"
    />

    @if (isFilterError) {
        <div class="max-w-[16.25rem] body-1 text-primarySecondary text-center">
            {{ tInvalidMessage('filter_invalid_field') }}

            <app-tb-button [appearance]="'link'" (click)="openFiltersDialog()" class="inline">
                {{ tInvalidMessage('change_filters') }}
            </app-tb-button>
        </div>
    }

    @if (isSortError) {
        <div class="max-w-[16.25rem] body-1 text-primarySecondary text-center">
            {{ tInvalidMessage('sort_invalid_field') }}

            <app-tb-button [appearance]="'link'" (click)="openSortDialog()" class="inline">
                {{ tInvalidMessage('change_sort') }}
            </app-tb-button>
        </div>
    }

    @if (isGroupError) {
        <div class="max-w-[16.25rem] body-1 text-primarySecondary text-center">
            {{ tInvalidMessage('group_invalid_field') }}

            <app-tb-button [appearance]="'link'" (click)="openGroupDialog()" class="inline">
                {{ tInvalidMessage('change_group') }}
            </app-tb-button>
        </div>
    }
</div>
