import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { FieldEntities, FieldType, Folder } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { FormFilterService } from '@app/views/view-controls/view-filter/form-filter.service'
import { MatIconModule } from '@angular/material/icon'
import { FilterTypesContainerComponent } from '../filter-types/filter-types-container.component'
import { TranslocoModule } from '@ngneat/transloco'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'
import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'

@UntilDestroy()
@Component({
    selector: 'app-filter-item',
    templateUrl: './filter-item.component.html',
    styleUrls: ['./filter-item.component.sass'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        TbSelectComponent,
        TbOptionComponent,
        FieldNameComponent,
        TranslocoModule,
        FilterTypesContainerComponent,
        MatIconModule,
        TbButtonComponent,
        TbIconComponent,
    ],
})
export class FilterItemComponent implements OnInit {
    @Input()
    selectedFolder!: Folder

    @Input()
    fields!: FieldEntities

    @Input()
    fieldGuids!: string[]

    @Input()
    fieldTypes!: FieldType[]

    @Input()
    totalItems!: number

    @Input()
    itemIndex!: number

    @Input()
    groupIndex!: number

    @Output()
    deleteGroup: EventEmitter<void> = new EventEmitter()

    @Output()
    deleteFilter: EventEmitter<number> = new EventEmitter()

    get formItem() {
        return this.formFilterService.getFilterItem(this.groupIndex, this.itemIndex) as FormGroup
    }

    get fieldGuid() {
        return this.formItem.controls['fieldGuid'].value
    }

    get fieldControlPlaceholder(): string {
        if (!!this.formItem.controls['fieldGuid'].errors?.['invalidField']) {
            return 'unknown'
        } else {
            return ''
        }
    }

    constructor(public formFilterService: FormFilterService) {}

    ngOnInit(): void {
        this.setOptionIfEmpty()

        this.formItem.controls['fieldGuid'].valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.setOptionDefault()
            })
    }

    fieldFilterOptions(fieldGuid: string) {
        const fieldType = this.fieldTypes.find(
            (type) => type.guid === this.fields[fieldGuid]?.field_type_code,
        )
        return fieldType?.filterOptions ?? []
    }

    removeFilter() {
        if (this.totalItems <= 1) {
            return this.deleteGroup.emit()
        }

        this.deleteFilter.emit(this.itemIndex)
    }

    private setOptionIfEmpty() {
        const option = this.formItem.controls['option']
        if (option.value) return

        this.setOptionDefault(option)
    }

    private setOptionDefault(option = this.formItem.controls['option']) {
        const filterOptions = this.fieldFilterOptions(this.formItem.controls['fieldGuid'].value)
        if (filterOptions && filterOptions.length) {
            option.setValue(filterOptions[0])
        }
    }
}
