<div class="mb-6" *transloco="let tRecordSettings; read: 'record_settings'">
    <div class="mb-2 text-primaryDark">{{ tRecordSettings('access') }}</div>
    <div
        (click)="pickAccess(AccessType.EVERYONE)"
        [class.selectedAccess]="compareAccessType(AccessType.EVERYONE)"
        class="group flex p-2 mb-2 rounded-lg border-2 border-solid border-transparent hover:border-newPrimaryLight cursor-pointer shadow-md"
    >
        <app-tb-icon icon="groups_outline" class="icon-lg mr-2 text-newTextLight"></app-tb-icon>

        <div>
            <div class="mb-1 text-newText body-1">
                {{ tRecordSettings('everyone') }}
            </div>
            <div class="caption-1 text-newTextLight">
                {{ tRecordSettings('everyone_description') }}
            </div>
        </div>
    </div>
    <div
        (click)="pickAccess(AccessType.ROLES)"
        [class.selectedAccess]="compareAccessType(AccessType.ROLES)"
        class="group flex p-2 mb-2 rounded-lg border-2 border-solid border-transparent hover:border-newPrimaryLight cursor-pointer shadow-md"
    >
        <app-tb-icon icon="group" class="icon-lg mr-2 text-newTextLight"></app-tb-icon>
        <div>
            <div class="mb-1 text-newText body-1">
                {{ tRecordSettings('roles') }}
            </div>
            <div class="caption-1 text-newTextLight">
                {{ tRecordSettings('roles_description') }}
            </div>
            <div class="hidden group-[.selectedAccess]:flex items-center">
                <mat-form-field class="w-full noHintError" appearance="outline">
                    <mat-select #rolesSelect multiple>
                        <!--                        options should be dynamically inserted-->
                        <mat-option value="yes">yes</mat-option>
                    </mat-select>
                    <mat-icon class="matSelectIconCustom cursor-pointer" matSuffix>{{
                        rolesSelect.panelOpen ? 'expand_less' : 'expand_more'
                    }}</mat-icon>
                </mat-form-field>
                <mat-icon
                    svgIcon="custom_settings"
                    class="ml-2 text-secondaryMainLight hover:text-secondaryMain"
                ></mat-icon>
            </div>
        </div>
    </div>
    <div
        (click)="pickAccess(AccessType.CUSTOM)"
        [class.selectedAccess]="compareAccessType(AccessType.CUSTOM)"
        class="group flex p-2 mb-2 rounded-lg border-2 border-solid border-transparent hover:border-newPrimaryLight cursor-pointer shadow-md"
    >
        <app-tb-icon
            icon="manage_accounts_outline"
            class="icon-lg mr-2 text-newTextLight"
        ></app-tb-icon>
        <div>
            <div class="mb-1 text-newText body-1">
                {{ tRecordSettings('custom') }}
            </div>
            <div class="caption-1 text-newTextLight">
                {{ tRecordSettings('custom_description') }}
            </div>
        </div>
    </div>
</div>
