<div class="w-full flex justify-between">
    <div class="flex items-center">
        <div class="flex w-[1.5rem] h-[1.5rem]">
            <app-tb-icon-toggle-button
                icon="push_pin"
                [isActive]="pinned"
                (toggle)="pin.emit()"
                class="mr-2 w-full h-full"
            ></app-tb-icon-toggle-button>
        </div>
        <app-field-name
            [field]="field"
            [folder]="selectedFolder"
            [showIcon]="false"
        ></app-field-name>
    </div>
    <app-tb-toggle [checked]="toggleValue" (valueChange)="toggle.emit($event)"></app-tb-toggle>
</div>
