<div
    cdkDrag
    cdkDragBoundary=".sort-container"
    [cdkDragData]="sortObject.fieldGuid"
    [cdkDragDisabled]="isDefault || !isSelected"
    class="flex items-center mb-4 dense-1"
>
    <app-tb-icon
        class="mr-1"
        icon="drag_indicator"
        [class]="isDefault || !isSelected ? 'text-newNeutral4' : 'text-newTextLight'"
    ></app-tb-icon>
    <app-tb-select
        *transloco="let tSort; read: 'sort'"
        class="w-full noHintError"
        [placeholder]="tSort(selectPlaceholder)"
        [formControl]="sortSelectionControl"
        [forceValidation]="true"
    >
        @for (field of fields; track field) {
            <app-tb-option [value]="field.guid">
                <app-field-name
                    [field]="field"
                    [folder]="selectedFolder"
                    [showIcon]="false"
                ></app-field-name>
            </app-tb-option>
        }
    </app-tb-select>
    <div class="px-1.5 mx-2 flex flex-col justify-center items-start self-stretch">
        <app-tb-icon
            [class]="ascButtonClass"
            (click)="changeDirection('asc')"
            class="cursor-pointer icon-xs"
            icon="custom_sort_up"
        >
        </app-tb-icon>
        <app-tb-icon
            [class]="descButtonClass"
            (click)="changeDirection('desc')"
            class="cursor-pointer icon-xs"
            icon="custom_sort_down"
        >
        </app-tb-icon>
    </div>
    <app-tb-icon
        (click)="removeItem()"
        class="cursor-pointer icon-m"
        [class]="closeIconColor"
        icon="close"
    >
    </app-tb-icon>
</div>
<div>
    @if (isInputError) {
        <span class="text-red-500 text-xs">{{ 'sort.field_not_exist' | transloco }}</span>
    }
</div>
