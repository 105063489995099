<div
    cdkDropList
    [cdkDropListData]="{ items, collapsed }"
    [cdkDropListEnterPredicate]="isDisabledDragIntoTheListBound"
    (cdkDropListDropped)="drop($event)"
    (cdkDropListEntered)="enter($event)"
    (cdkDropListExited)="exit($event)"
    class="w-[18.75rem]"
    [class.collapsed]="collapsed"
    [class]="collapsed ? '' : 'overflow-auto h-full'"
>
    <div class="mb-2">
        <app-tb-board-column-header
            [items]="items"
            [moreMenu]="headerMenu"
            [columnItem]="columnItem"
            [selectedItems]="selectedItems"
            [dragOn]="dragOn"
            [collapsed]="collapsed"
            [controlCollapse]="isCollapsedSet"
            (collapseChanged)="toggleCollapse($event)"
            (selectColumn)="selectColumn.emit($event)"
        >
            <ng-content select="[header]"></ng-content>
        </app-tb-board-column-header>
    </div>
    @if (injector) {
        <div [class.hidden]="collapsed">
            @for (item of items; track item) {
                <div class="mb-2">
                    <ng-container
                        *ngTemplateOutlet="card; injector: injector; context: { item }"
                    ></ng-container>
                </div>
            }
        </div>
    }
</div>
