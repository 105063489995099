import { Component, OnInit } from '@angular/core'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { Folder, isFolderGlobal, Schema } from '@core/models'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { dirtyCheck } from '@ngneat/dirty-check-forms'
import { schemaIconByName } from '@shared/models/icons'
import { of } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CommonDialogResultStatus } from '@components-library/tb-confirmation-popup/tb-confirmation-dialog.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { AccessSelectorComponent } from '@shared/access-selector/access-selector.component'
import { EnvDirective } from '@shared/env.directive'
import { TbOptionComponent } from '@components-library/tb-select/components/tb-option/tb-option.component'

import { TbSelectComponent } from '@components-library/tb-select/components/tb-select/tb-select.component'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule } from '@ngneat/transloco'

export interface RecordSettingsData {
    schemas: Schema[]
    folders: Folder[]
    selectedFoldersGuid: string[]
    selectedSchemaGuid: string
}

interface RecordSettingsFormGroup {
    folder: FormControl<string[] | null>
    schema: FormControl<string | null>
    template: FormControl<string | null>
    access: FormControl<string | null>
}

@UntilDestroy()
@Component({
    selector: 'app-record-settings-content',
    templateUrl: './record-settings-content.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        ReactiveFormsModule,
        TbSelectComponent,
        TbOptionComponent,
        EnvDirective,
        AccessSelectorComponent,
        TbButtonComponent,
        TbMenuListComponent,
        TbDividerComponent,
    ],
})
export class RecordSettingsContentComponent
    extends ModalContainerComponent<RecordSettingsData>
    implements OnInit
{
    protected readonly schemaIconByName = schemaIconByName

    settingsFormGroup!: FormGroup<RecordSettingsFormGroup>
    initialFoldersGuid!: string[]
    initialSchemaGuid!: string

    globalFolder!: Folder
    availableFolders!: Folder[]

    isDirty = false

    ngOnInit() {
        this.initialFoldersGuid = this.data.selectedFoldersGuid
        this.initialSchemaGuid = this.data.selectedSchemaGuid

        this.globalFolder = this.data.folders.find((folder) => isFolderGlobal(folder))!
        this.availableFolders = this.data.folders.filter(
            (folder) => folder.guid !== this.globalFolder.guid,
        )

        this.settingsFormGroup = new FormGroup({
            folder: new FormControl(this.initialFoldersGuid),
            schema: new FormControl(this.initialSchemaGuid),
            template: new FormControl(''),
            access: new FormControl(''),
        })

        dirtyCheck(this.settingsFormGroup, of(this.settingsFormGroup.value))
            .pipe(untilDestroyed(this))
            .subscribe((isDirty) => (this.isDirty = isDirty))
    }

    cancelSettingsChange() {
        if (!this.hasChanged()) {
            this.modalManagerService.close()
            return
        }

        this.openConfirmationDialog()
    }

    openConfirmationDialog() {
        this.confirmationDialogService
            .openCommon({
                translations: {
                    title: 'confirmation_dialog.discard_changes',
                    message: 'confirmation_dialog.unsaved_data_message',
                    cancel: 'confirmation_dialog.close_anyway',
                    confirm: 'confirmation_dialog.keep_editing',
                },
            })
            .subscribe((result) => {
                if (result === CommonDialogResultStatus.CANCEL) {
                    this.modalManagerService.close()
                }
            })
    }

    applySettingsChange() {
        this.modalManagerService.close({
            folderGuids: this.settingsFormGroup.value.folder,
            schemaGuid: this.settingsFormGroup.value.schema,
        })
    }

    hasChanged(): boolean {
        return this.isDirty
    }
}
