import { Component, Input } from '@angular/core'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    standalone: true,
    imports: [TbIconComponent],
})
export class UserAvatarComponent {
    @Input()
    userUrl!: string | undefined

    @Input()
    userName!: string

    @Input()
    unknown = false

    @Input()
    small: boolean = false

    @Input()
    border: boolean = false

    get avatarClass(): string {
        const borderStyle = this.border ? 'border border-newNeutral1' : ''
        if (this.small) {
            return `h-5 w-5 ${borderStyle}`
        }
        return `h-6 w-6 ${borderStyle}`
    }

    get iconBgClass() {
        const bgClass = []
        if (this.unknown) {
            bgClass.push('bg-newTextLight')
        } else {
            bgClass.push('bg-newPrimaryLight')
        }

        if (this.small) {
            bgClass.push('w-5')
            bgClass.push('h-5')
        } else {
            bgClass.push('w-6')
            bgClass.push('h-6')
        }

        return bgClass
    }
}
