import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BaseInputCellComponent } from '@app/feature/input-cells/base-input-cell/base-input-cell.component'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-text-one-line-cell',
    standalone: true,
    imports: [ReactiveFormsModule, TranslocoModule],
    templateUrl: './text-one-line-cell.component.html',
    styleUrl: './text-one-line-cell.component.sass',
})
export class TextOneLineCellComponent extends BaseInputCellComponent<string> implements OnInit {
    @ViewChild('input')
    _inputElement!: ElementRef<HTMLElement>

    @Input() placeholder = 'cell_placeholder.text'

    transformedValue(): string {
        return this.value
    }

    get inputElement() {
        return this._inputElement
    }
}
