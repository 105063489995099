import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Folder, Schema } from '@core/models'
import { schemaIconByName } from '@shared/models/icons'
import {
    RecordSettingsContentComponent,
    RecordSettingsData,
} from './record-settings-content/record-settings-content.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { EnvDirective } from '@shared/env.directive'
import { NgTemplateOutlet } from '@angular/common'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-add-record-settings-section',
    templateUrl: './add-record-settings-section.component.html',
    styleUrl: './add-record-settings-section.component.sass',
    standalone: true,
    imports: [TranslocoModule, NgTemplateOutlet, EnvDirective, TbIconComponent],
})
export class AddRecordSettingsSectionComponent {
    protected readonly schemaIconByName = schemaIconByName

    @Input() folders!: Folder[]
    @Input() schemas!: Schema[]
    @Input() pickedFolders!: Folder[]
    @Input() selectedSchema!: Schema

    @Output() saveSettings = new EventEmitter<{
        folderGuids: string[]
        schemaGuid: string
    }>()

    constructor(protected modalManagerService: ModalManagerService) {}

    openDialog() {
        const dialogRef = this.modalManagerService.openDialog<
            RecordSettingsContentComponent,
            RecordSettingsData,
            { folderGuids: string[]; schemaGuid: string }
        >({
            component: RecordSettingsContentComponent,
            data: {
                folders: this.folders,
                schemas: this.schemas,
                selectedFoldersGuid: this.pickedFolders.map((folder) => folder.guid),
                selectedSchemaGuid: this.selectedSchema.guid,
            },
        })

        dialogRef.subscribe((data) => {
            if (data) {
                this.saveSettings.emit({
                    folderGuids: data.folderGuids,
                    schemaGuid: data.schemaGuid,
                })
            }
        })
    }

    hiddenElementsCounter() {
        return `+${this.pickedFolders.length - 1}`
    }
}
