<ng-container *transloco="let tRecordSettings; read: 'record_settings'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        (closeModal)="forceWithValidation()"
        class="w-[31.25rem] block"
    >
        <ng-container title>
            <div>{{ tRecordSettings('record_settings') }}</div>
        </ng-container>
        <ng-container [formGroup]="settingsFormGroup">
            <div class="my-6 flex flex-col">
                <div class="mb-1 body-1">{{ tRecordSettings('workspace') }}</div>
                <app-tb-select [shaded]="true" [multiple]="true" formControlName="folder">
                    <app-tb-option
                        [disabled]="true"
                        [icon]="globalFolder.icon.value"
                        [iconColor]="globalFolder.color.value"
                        [value]="globalFolder.guid"
                        [divideAfter]="true"
                        >{{ globalFolder.name.value }}</app-tb-option
                    >

                    @for (folder of availableFolders; track folder) {
                        <app-tb-option
                            [value]="folder.guid"
                            [icon]="folder.icon.value"
                            [iconColor]="folder.color.value"
                        >
                            {{ folder.name.value }}
                        </app-tb-option>
                    }
                </app-tb-select>
                <div class="mt-1 caption-1 text-newTextLight">
                    {{ tRecordSettings('select_description') }}
                </div>
            </div>
            <div *transloco="let tSchemaReadableName; read: 'nav_menu'">
                <div class="mb-1 body-1">{{ tRecordSettings('type') }}</div>
                <app-tb-select formControlName="schema" tagIconColor="newPrimary">
                    @for (schema of data.schemas; track schema) {
                        <app-tb-option
                            [icon]="schemaIconByName[schema.name]"
                            [value]="schema.guid"
                            [active]="schema.guid === settingsFormGroup.value.schema"
                            iconColor="newPrimary"
                        >
                            {{ tSchemaReadableName(schema.name) }}
                        </app-tb-option>
                    }
                </app-tb-select>
            </div>
            <div *appEnv="'prod'; visible: false" class="mt-6">
                <div class="mb-1 text-primaryDark">{{ tRecordSettings('template') }}</div>
                <app-tb-select>
                    <app-tb-option value="values">$Values here</app-tb-option>
                    <app-tb-option>$Values here</app-tb-option>
                    <app-tb-option>$Values here</app-tb-option>
                </app-tb-select>
            </div>
            <app-access-selector *appEnv="'prod'; visible: false"></app-access-selector>
        </ng-container>
        <ng-container *transloco="let tButtons; read: 'buttons'" buttons>
            <div class="flex justify-between">
                <app-tb-button
                    appearance="text"
                    icon="custom_arrow_back"
                    (click)="cancelSettingsChange()"
                >
                    {{ tButtons('back') }}
                </app-tb-button>

                <app-tb-button (click)="applySettingsChange()">
                    {{ tButtons('apply') }}
                </app-tb-button>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
