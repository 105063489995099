import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FieldOptionsMenuComponent } from '@app/feature/manage-fields/field-options-menu/field-options-menu.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbTagComponent } from '@components-library/tb-tags'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { Field, FieldType, Folder } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
    selector: 'app-manage-fields-item',
    standalone: true,
    imports: [
        FieldOptionsMenuComponent,
        TbButtonComponent,
        TbIconComponent,
        TbMenuComponent,
        TbTagComponent,
        TbTooltipComponent,
        TranslocoModule,
    ],
    templateUrl: './manage-fields-item.component.html',
    styleUrl: './manage-fields-item.component.sass',
})
export class ManageFieldsItemComponent {
    @Input({ required: true }) folder!: Folder

    @Input({ required: true }) field!: Field

    @Input() fieldType?: FieldType

    @Output() deleteField = new EventEmitter<Field>()
}
