import { Component, Inject } from '@angular/core'
import { ManageFieldsItemComponent } from '@app/feature/manage-fields/manage-fields-item/manage-fields-item.component'
import { ManageFieldsService } from '@app/feature/manage-fields/manage-fields.service'
import {
    FieldTypeSelectorComponent,
    FieldTypeSelectorData,
} from '@app/shared/field-type-selector/field-type-selector.component'
import { ConfirmationDialogService } from '@components-library/services/confirmation-dialog.service'
import { ModalContainerComponent } from '@components-library/tb-modal-manager/modal-container-component/modal-container.component'
import { ModalContainerDataToken } from '@components-library/tb-modal-manager/modal-container-factory.service'
import { ModalManagerService } from '@components-library/tb-modal-manager/modal-manager.service'
import { Field, FieldTypes, Folder, UpdateResponseModel } from '@core/models'
import {
    FieldTypeFacadeService,
    FolderFacadeService,
    SchemaFacadeService,
} from '@core/services/store-facade'
import { FieldOptionsMenuComponent } from './field-options-menu/field-options-menu.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { TbTagComponent } from '@components-library/tb-tags'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { TypedTemplateDirective } from '@app/shared/typed-template.directive'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import {
    AccordionContentContext,
    FieldsCollectionComponent,
} from '@app/shared/fields-collection/fields-collection.component'
import { ModalLayoutComponent } from '@components-library/tb-modal-manager/modal-layout/modal-layout.component'
import { TranslocoModule } from '@ngneat/transloco'
import { AsyncPipe } from '@angular/common'

@Component({
    selector: 'app-manage-fields',
    templateUrl: './manage-fields.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ModalLayoutComponent,
        FieldsCollectionComponent,
        TbButtonComponent,
        TypedTemplateDirective,
        TbTooltipComponent,
        TbIconComponent,
        TbTagComponent,
        TbMenuComponent,
        FieldOptionsMenuComponent,
        AsyncPipe,
        ManageFieldsItemComponent,
    ],
})
export class ManageFieldsComponent extends ModalContainerComponent<unknown, void> {
    readonly excludedFieldTypes = [FieldTypes.NAME] as string[]

    fieldTypes$ = this.fieldTypeFacadeService.selectFieldTypeEntities$
    folders$ = this.folderFacadeService.selectAllFolders$
    selectedSchema$ = this.schemaFacadeService.selectSelectedTableSchema$
    selectedFolder$ = this.folderFacadeService.selectSelectedFolder$

    contextType = {} as AccordionContentContext

    constructor(
        @Inject(ModalContainerDataToken) protected data: unknown,
        modalManagerService: ModalManagerService,
        confirmationPopupService: ConfirmationDialogService,
        private schemaFacadeService: SchemaFacadeService,
        private fieldTypeFacadeService: FieldTypeFacadeService,
        private folderFacadeService: FolderFacadeService,
        private manageFieldsService: ManageFieldsService,
    ) {
        super(data, modalManagerService, confirmationPopupService)
    }

    addField(selectedFolder: Folder) {
        this.modalManagerService.openDialog<FieldTypeSelectorComponent, FieldTypeSelectorData>({
            component: FieldTypeSelectorComponent,
            data: { selectedFolder },
        })
    }

    deleteField(field: Field) {
        this.wrapResponseObservable<UpdateResponseModel>(
            this.manageFieldsService.deleteField(field.guid),
        ).subscribe((data) => {
            if (data.status === 'success') {
                this.close()
            }
        })
    }
}
