import { Component, Input, OnInit } from '@angular/core'
import { isNonNull } from '@core/global-util'
import { Field, Folder } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy } from '@ngneat/until-destroy'
import { FolderFacadeService } from '@services/store-facade'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { filter } from 'rxjs'
import { take } from 'rxjs/operators'

enum CellLabelStyles {
    form = 'form',
    caption = 'caption',
}

@UntilDestroy()
@Component({
    selector: 'app-cell-label',
    standalone: true,
    imports: [TranslocoModule, FieldNameComponent],
    templateUrl: './cell-label.component.html',
    styleUrl: './cell-label.component.sass',
})
export class CellLabelComponent implements OnInit {
    @Input({ required: true })
    field!: Field

    @Input()
    appearance: keyof typeof CellLabelStyles = CellLabelStyles.form

    @Input()
    full: boolean = false

    @Input()
    folder?: Folder

    constructor(private folderFacadeService: FolderFacadeService) {}

    ngOnInit(): void {
        if (this.full && !this.folder) {
            this.folderFacadeService.selectSelectedFolder$
                .pipe(filter(isNonNull), take(1))
                .subscribe((folder) => {
                    this.folder = folder
                })
        }
    }

    get labelClass() {
        const classes = {
            [CellLabelStyles.form]: 'body-1',
            [CellLabelStyles.caption]: 'caption-1',
        }

        return [
            classes[this.appearance],
            this.appearance === CellLabelStyles.caption ? 'text-newTextLight' : '',
        ]
    }
}
