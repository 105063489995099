import { Component, ElementRef, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { BaseCellComponent } from '@app/feature/input-cells/base-container-content'
import { untilDestroyed } from '@ngneat/until-destroy'

@Component({
    template: '',
})
export abstract class BaseInputCellComponent<ValueType>
    extends BaseCellComponent
    implements OnInit
{
    // todo: remove compareValue make isDirty approach
    compareValue: ValueType | null = null

    control = new FormControl<ValueType | null>(null)

    placeholderOnMouseover = false

    abstract transformedValue(): ValueType

    abstract get inputElement(): ElementRef<HTMLElement>

    ngOnInit(): void {
        this.placeholderOnMouseover = !!this.styleConfig?.placeholder_on_mouseover

        if (this.value) {
            this.control.setValue(this.transformedValue())
        }

        this.mergeValidators(this.control)

        this.compareValue = this.control.value

        if (this.disabled) {
            this.control.disable()
        }

        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.value = value?.toString() || ''
        })
    }

    emitValue() {
        const value = this.control.value
        if (this.compareValue === value) return

        this.compareValue = value
        this.valueChange.emit(value?.toString())
        this.onChange(value?.toString())
        this.onTouched()
    }

    writeValue(value: string): void {
        this.value = value
        this.control.setValue(value ? this.transformedValue() : null)
        this.compareValue = this.control.value
        this.cd.detectChanges()
    }

    override setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled

        isDisabled ? this.control.disable() : this.control.enable()
    }

    onContainerClick(): void {}

    onFocusClick() {
        if (!this.readonly) {
            this.inputElement.nativeElement.focus()
        }
    }

    onOutsideClick(): void {
        if (this.focused) {
            this.inputContainer.resetActive()
            this.removeFocus()
            this.emitValue()
        }
    }

    onFocusIn() {
        if (!this.focused && !this.readonly && !this.styleConfig?.disable_hover_and_mouse_events) {
            this.focused = true
            this.inputContainer.makeFocused()
        }
    }

    onFocusOut(event: FocusEvent) {
        if (this.readonly || !this.styleConfig?.disable_hover_and_mouse_events) return

        const contains = this.inputContainer.elementRef.nativeElement.contains(
            event.relatedTarget as Element,
        )

        if (this.inputContainer.editControls && !contains) {
            return
        }

        if (!contains) {
            !this.control.invalid && this.emitValue()
            this.makeTouchedAndUnfocused()
        }
    }

    removeFocus() {
        this.makeTouchedAndUnfocused()
        this.inputElement.nativeElement.blur()
    }

    resetValue() {
        this.control.setValue(this.compareValue)
    }

    get errorState() {
        return this.control.invalid && this.touched
    }

    get loadingState() {
        return this.loading
    }

    get placeholderText() {
        if (this.focused) return ''

        if (!this.placeholderOnMouseover) return this.placeholder

        return this.mouseOver ? this.placeholder : ''
    }
}
