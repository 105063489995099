<ng-container *transloco="let tSort; read: 'sort'">
    <app-modal-layout
        class="block w-[540px]"
        [containerLayout]="containerLayout"
        [showLoader]="showLoader"
        [errors]="errors"
        (keydown.tab)="$event.stopPropagation()"
        (closeModal)="forceWithValidation()"
    >
        <ng-container title>
            <span class="text-newText">{{ tSort('sort_by') }}</span>
        </ng-container>
        <div
            (click)="$event.stopPropagation()"
            [cdkDropListData]="sortObjectsArray"
            (cdkDropListDropped)="drop($event)"
            cdkDropList
            class="sort-container max-sm:mt-4 md:w-[480px] filter-area body-1 leading-4"
        >
            <div class="flex justify-end items-center mb-4">
                <div
                    (click)="onClear()"
                    class="flex items-center cursor-pointer body-2 py-1 px-2 uppercase"
                    [class]="isSortDefault() ? 'text-newPrimary' : 'text-newNeutral4'"
                >
                    <span>{{ tSort('clear_all') }}</span>
                </div>
            </div>
            @if (sortObjectsArray.length) {
                <form [formGroup]="sortMenuForm">
                    @if (selectedFolder$ | async; as selectedFolder) {
                        @for (object of sortObjectsArray; track object; let index = $index) {
                            <app-sort-item
                                [isDefault]="!isSortDefault()"
                                [fields]="fieldsForSortItem(object)"
                                [selectedFolder]="selectedFolder"
                                [sortObject]="object"
                                [sortGroup]="getSortItemGroup(object.fieldGuid)"
                                (changed)="updateSortObjects($event, index)"
                                (removed)="removeSortObject($event, index)"
                                (selected)="isNotSelectedItem = $event"
                            ></app-sort-item>
                        }
                    } @else {
                        <span>some error message</span>
                    }
                </form>
            }
            <app-tb-button
                (click)="addSort()"
                [appearance]="'text'"
                [class.text-newNeutral4]="!isSortDefault()"
                [disabled]="!isSortDefault()"
                [icon]="'add_circle_outline'"
            >
                <span>{{ tSort('add_sort') }}</span>
            </app-tb-button>
        </div>
        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end items-center">
                <app-tb-button
                    appearance="outlined"
                    (click)="forceWithValidation()"
                    class="mr-4 whitespace-nowrap"
                >
                    <span class="body-2">{{ tButtons('cancel') }}</span>
                </app-tb-button>
                <div class="flex gap-1">
                    <app-tb-button appearance="primary" (click)="preview()">
                        {{ tButtons('preview') }}
                    </app-tb-button>
                    <app-tb-menu [template]="saveMenu" #tbMenu xPosition="before" yPosition="above">
                        <app-tb-button
                            trigger
                            appearance="iconPrimary"
                            [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                            [class.newPrimaryDark]="tbMenu.menuOpen"
                        ></app-tb-button>
                    </app-tb-menu>
                </div>
                <ng-template #saveMenu>
                    <app-tb-menu-list class="w-[8.75rem] block">
                        <div class="ml-[-0.5rem] mr-[-0.5rem]">
                            <div class="mb-1">
                                <app-tb-menu-list-item (click)="resetToSave()" class="mb-1">{{
                                    tButtons('reset_to_saved')
                                }}</app-tb-menu-list-item>
                            </div>
                            <app-tb-menu-list-item (click)="resetToDefault()">{{
                                tButtons('reset_to_default')
                            }}</app-tb-menu-list-item>
                            <div class="px-2 my-2">
                                <app-tb-divider></app-tb-divider>
                            </div>
                            <app-tb-menu-list-item (click)="save()">{{
                                tButtons('save')
                            }}</app-tb-menu-list-item>
                        </div>
                    </app-tb-menu-list>
                </ng-template>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
<!-- TODO: default error message, correct will be added later -->
<ng-template #error>
    <span>some error message</span>
</ng-template>
