import { Component, Input } from '@angular/core'
import { CellOptionsMenuComponent } from '@app/feature/input-cells/cell-options-menu/cell-options-menu.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { TbMenuComponent } from '@components-library/tb-menu'
import { BusinessRecord } from '@models/ui'

@Component({
    selector: 'app-cell-edit-buttons',
    standalone: true,
    imports: [CellOptionsMenuComponent, TbButtonComponent, TbMenuComponent],
    templateUrl: './cell-edit-buttons.component.html',
})
export class CellEditButtonsComponent {
    @Input({ required: true }) record!: BusinessRecord

    @Input({ required: true }) onFocus!: () => void
    @Input({ required: true }) onCopy!: () => void
    @Input({ required: true }) onClear!: () => void
    @Input({ required: true }) toggleIcons!: () => void

    @Input() focusDisabled = false
    @Input() currentRecordOpened = false
}
