import { Component, OnInit } from '@angular/core'
import { CustomizeViewModalComponent } from '@app/views/view-controls/customize-view/customize-view-modal/customize-view-modal.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { Folder } from '@core/models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CustomizeViewStorageService } from '@services/local-storage/customize-view-storage.service'
import { FolderFacadeService, ViewFacadeService } from '@services/store-facade'
import { ViewErrorType, ViewValidatorService } from '@services/view-validator.service'
import { FieldNameComponent } from '@shared/field-name/field-name.component'
import { TbButtonComponent } from '@components-library/tb-button/tb-button.component'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { TranslocoModule } from '@ngneat/transloco'
import { combineLatest } from 'rxjs'

/**
 * Item in view controls menu which opens a dialog with visible/hidden columns
 */
@UntilDestroy()
@Component({
    selector: 'app-customize-view',
    templateUrl: './customize-view.component.html',
    styleUrls: ['./customize-view.component.sass'],
    standalone: true,
    imports: [
        TranslocoModule,
        MatMenuModule,
        MatIconModule,
        NgFor,
        NgIf,
        TbButtonComponent,
        FieldNameComponent,
        AsyncPipe,
    ],
})
export class CustomizeViewComponent implements OnInit {
    selectedFolder!: Folder

    isViewHideColumnsSet = false

    hasNoCustomizeViewErrors$ = this.viewValidatorService
        .validateViewErrors({
            filter: [
                ViewErrorType.filterInvalidField,
                ViewErrorType.sortInvalidField,
                ViewErrorType.groupInvalidField,
            ],
        })
        .pipe(untilDestroyed(this))

    constructor(
        private modalFlowManagerService: ModalFlowManagerService,
        private showStorageService: CustomizeViewStorageService,
        private folderFacadeService: FolderFacadeService,
        private viewFacadeService: ViewFacadeService,
        private viewValidatorService: ViewValidatorService,
    ) {}

    ngOnInit() {
        combineLatest([
            this.folderFacadeService.selectSelectedFolder$,
            this.viewFacadeService.selectedView$,
            this.showStorageService.getStore$(),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(([folder, view]) => {
                if (!view) return

                this.selectedFolder = folder
                this.isViewHideColumnsSet = !!view.columns_hide.value
            })
    }

    openShowModal() {
        this.modalFlowManagerService.openDialog({
            component: CustomizeViewModalComponent,
            data: this.selectedFolder,
        })
    }
}
