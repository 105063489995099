import { Component, Input } from '@angular/core'
import { ManageFieldsComponent } from '@app/feature/manage-fields/manage-fields.component'
import { CardComponent, DialogCardInput } from '@app/feature/record-card/card.component'
import { ShareRecordComponent } from '@app/feature/share-record/share-record.component'
import { ModalFlowManagerService } from '@components-library/tb-modal-manager/modal-flow-manager.service'
import { AppRecord, BusinessRecords } from '@models/ui'
import { TranslocoModule } from '@ngneat/transloco'
import { RecordsService } from '@services/records.service'
import { UserFacadeService } from '@services/store-facade'
import { UiFacadeService } from '@services/store-facade/ui-facade.service'
import { EnvDirective } from '@shared/env.directive'
import { TbDividerComponent } from '@components-library/tb-divider/tb-divider.component'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'
import { TbMenuListComponent } from '@components-library/tb-menu-list/tb-menu-list.component'
import { TbMenuListItemComponent } from '@components-library/tb-menu-list-item/tb-menu-list-item.component'
import { EMPTY, switchMap } from 'rxjs'
import { take } from 'rxjs/operators'

@Component({
    selector: 'app-cell-options-menu',
    standalone: true,
    imports: [
        EnvDirective,
        TbDividerComponent,
        TbIconComponent,
        TbMenuListComponent,
        TbMenuListItemComponent,
        TranslocoModule,
    ],
    templateUrl: './cell-options-menu.component.html',
})
export class CellOptionsMenuComponent {
    @Input({ required: true }) record!: BusinessRecords

    @Input({ required: true }) onFocus!: () => void
    @Input({ required: true }) onCopy!: () => void
    @Input({ required: true }) onClear!: () => void

    @Input() focusDisabled = false
    @Input() currentRecordOpened = false

    constructor(
        private modalFlowManagerService: ModalFlowManagerService,
        private uiFacadeService: UiFacadeService,
        private recordsService: RecordsService,
        private userFacadeService: UserFacadeService,
    ) {}

    openCard(guid: string) {
        this.modalFlowManagerService.openDialog<CardComponent, DialogCardInput>({
            component: CardComponent,
            data: {
                recordGuid: guid,
                isFolder: true,
            },
        })
    }

    duplicate(record: BusinessRecords) {
        this.recordsService.duplicateRecord(record)
    }

    share(record: BusinessRecords) {
        this.modalFlowManagerService
            .openDialog<ShareRecordComponent, AppRecord, AppRecord>({
                component: ShareRecordComponent,
                data: record,
            })
            .subscribe((record) => {
                if (record) {
                    this.recordsService.updateRecord({ record }).subscribe()
                }
            })
    }

    manage() {
        this.modalFlowManagerService.openDialog({
            component: ManageFieldsComponent,
        })
    }

    delete(record: BusinessRecords) {
        this.uiFacadeService
            .wrapRequestWithViewLoader(this.recordsService.deleteRecord(record))
            .subscribe()
    }

    favorite(record: BusinessRecords) {
        this.userFacadeService.currentUser$
            .pipe(
                take(1),
                switchMap((user) => {
                    if (!user) return EMPTY

                    return this.uiFacadeService.wrapRequestWithViewLoader(
                        this.recordsService.toggleFavorite(record, user.guid),
                    )
                }),
            )
            .subscribe()
    }
}
