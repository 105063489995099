<app-tb-menu
    [template]="menu"
    [yPosition]="'below'"
    class="w-full group"
    [disabled]="!!styleConfig?.disable_hover_and_mouse_events || this.loading"
    *transloco="let t"
>
    <div class="w-full" trigger>
        @if (textControl.value) {
            <div class="body-1 truncate w-full">{{ textControl.value }}</div>
        } @else if (!textControl.value && !focused && placeholder) {
            @if (placeholderOnMouseover) {
                <div
                    class="visible group-hover:invisible body-1 truncate w-full text-newTextLight"
                ></div>
                <div class="invisible group-hover:visible body-1 truncate w-full text-newTextLight">
                    {{ t(placeholder) }}
                </div>
            } @else {
                <div class="body-1 truncate w-full text-newTextLight">{{ t(placeholder) }}</div>
            }
        }
    </div>
</app-tb-menu>

<ng-template #menu let-containerLayout="containerLayout" let-closeFn="closeFn">
    <app-modal-layout [containerLayout]="containerLayout">
        <div (click)="$event.stopPropagation()" class="flex flex-col min-w-[26.5rem]">
            <div class="flex-1 overflow-auto">
                <textarea
                    class="w-full min-h-[3.75rem] body-1 resize"
                    [formControl]="textControl"
                ></textarea>
            </div>
            @if (showButtons) {
                <div
                    class="flex mt-2 w-full justify-between items-center"
                    *transloco="let tMultiline; read: 'cell_types.multiline'"
                >
                    <app-tb-button icon="custom_copy" appearance="text" (click)="copy()">
                        {{ tMultiline('copy') }}
                    </app-tb-button>

                    @if (isEdit) {
                        <div class="flex items-center justify-end">
                            <app-tb-button
                                icon="check"
                                appearance="text"
                                (click)="closeFn(); save()"
                                >{{ tMultiline('save') }}
                            </app-tb-button>
                            <app-tb-button
                                icon="close"
                                appearance="text"
                                color="secondary"
                                (click)="closeFn(); cancel()"
                                >{{ tMultiline('cancel') }}
                            </app-tb-button>
                        </div>
                    }
                </div>
            }
        </div>
    </app-modal-layout>
</ng-template>
