import { Component, Input } from '@angular/core'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { TbTab } from '@components-library/tb-tab/tb-tab.component'

@Component({
    selector: 'app-tb-spinner',
    standalone: true,
    imports: [MatProgressSpinnerModule],
    templateUrl: './tb-spinner.component.html',
    styleUrl: './tb-spinner.component.sass',
})
export class TbSpinnerComponent {
    @Input()
    size: number = 60
}
