import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BaseInputCellComponent } from '@app/feature/input-cells/base-input-cell/base-input-cell.component'
import { TranslocoModule } from '@ngneat/transloco'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
    selector: 'app-number-cell',
    standalone: true,
    imports: [ReactiveFormsModule, TranslocoModule],
    templateUrl: './number-cell.component.html',
    styleUrl: './number-cell.component.sass',
})
export class NumberCellComponent extends BaseInputCellComponent<number> implements OnInit {
    @ViewChild('input')
    _inputElement!: ElementRef<HTMLElement>

    @Input() placeholder = 'cell_placeholder.number'

    transformedValue(): number {
        return Number(this.value)
    }

    get lessThanZero() {
        return this.control.value && this.control.value < 0
    }

    get inputElement() {
        return this._inputElement
    }
}
