@if (!focused && styleConfig?.show_extra_info) {
    <div
        class="w-1 top-0 bottom-0 left-0 absolute rounded-l-lg"
        [style]="{ 'background-color': statusColor }"
    ></div>
}
<div class="flex items-center" *transloco="let t">
    @if (!focused && extraData?.row_order !== undefined && styleConfig?.show_extra_info) {
        <span class="caption-1 text-newTextLight min-w-[1.25rem] inline-block pr-1 text-center">{{
            extraData?.row_order
        }}</span>
    }

    <input
        #input
        type="text"
        class="body-1 w-full bg-transparent"
        [formControl]="control"
        (focusin)="onFocusIn()"
        (focusout)="onFocusOut($event)"
        [placeholder]="!focused ? t(placeholder) : ''"
        [readOnly]="readonly || loadingState || styleConfig?.disable_hover_and_mouse_events"
    />
</div>
