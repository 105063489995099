<app-tb-menu-list *transloco="let tCellOptionsMenu; read: 'cell_options_menu'">
    @if (!focusDisabled) {
        <app-tb-menu-list-item icon="custom_edit" (click)="onFocus()">{{
            tCellOptionsMenu('edit')
        }}</app-tb-menu-list-item>
        <app-tb-menu-list-item
            icon="custom_copy"
            (click)="onCopy()"
            *appEnv="'prod'; visible: false"
        >
            {{ tCellOptionsMenu('copy') }}
        </app-tb-menu-list-item>
        <app-tb-menu-list-item icon="close" (click)="onClear()" *appEnv="'prod'; visible: false">
            {{ tCellOptionsMenu('clear') }}
        </app-tb-menu-list-item>
        <app-tb-menu-list-item icon="custom_chatbubble_ellipses" *appEnv="'prod'; visible: false">
            {{ tCellOptionsMenu('comment') }}
        </app-tb-menu-list-item>

        <app-tb-divider></app-tb-divider>
    }

    @if (!currentRecordOpened) {
        <app-tb-menu-list-item icon="custom_open" (click)="openCard(record.guid)">
            {{ tCellOptionsMenu('open_record') }}
        </app-tb-menu-list-item>
    }
    <app-tb-menu-list-item icon="control_point_duplicate" (click)="duplicate(record)">
        {{ tCellOptionsMenu('duplicate_record') }}
    </app-tb-menu-list-item>
    <app-tb-menu-list-item *appEnv="'prod'; visible: false" icon="custom_subtask">
        {{ tCellOptionsMenu('add_subtask') }}
    </app-tb-menu-list-item>
    <app-tb-menu-list-item icon="star" (click)="favorite(record)">
        {{ tCellOptionsMenu('favorite_record') }}
    </app-tb-menu-list-item>
    <app-tb-menu-list-item *appEnv="'prod'; visible: false" icon="share" (click)="share(record)">
        {{ tCellOptionsMenu('share_record') }}
    </app-tb-menu-list-item>
    <app-tb-menu-list-item icon="edit_note" (click)="manage()">
        {{ tCellOptionsMenu('manage_fields') }}
    </app-tb-menu-list-item>

    <app-tb-divider></app-tb-divider>

    <app-tb-menu-list-item (click)="delete(record)">
        <app-tb-icon color="red-600" icon="custom_trash" item-icon></app-tb-icon>
        <span class="text-red-600" item-title>{{ tCellOptionsMenu('delete_record') }}</span>
    </app-tb-menu-list-item>
</app-tb-menu-list>
