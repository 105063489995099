<div [formGroup]="formGroup" class="mb-5" *transloco="let tFilter; read: 'filter'">
    @if (!single && !first) {
        <app-filter-combination-toggle
            class="flex w-fit mx-auto my-4 andOrToggle"
            [disabled]="isCombinationGroupToggleDisabled"
            [value]="formFilterService.getGroupsCombinationType"
            [combinationTypes]="filterGroupsCombinationTypes"
            (valueChange)="groupCombinationTypeChange($event)"
            translateKey="filter.groups_combination_types"
        ></app-filter-combination-toggle>
    }
    <div [class.notEmpty]="!isEmpty" [formGroup]="formGroup" class="p-2 rounded-lg bg-newNeutral2">
        <div [class.emptyTemplate]="isEmpty" class="flex flex-col">
            @if (!isEmpty) {
                <ng-container *ngTemplateOutlet="filterItem"></ng-container>
            } @else {
                <div class="flex flex-col w-full" *transloco="let tFilter; read: 'filter'">
                    <div class="mb-4 subheading-1 text-newTextLight">
                        {{ tFilter('where_label') }}
                    </div>
                    <app-filter-empty-item
                        [fieldGuids]="fieldGuids"
                        [selectedFolder]="selectedFolder"
                        [fields]="fields"
                        (setFilterItem)="addFilterClick($event)"
                    ></app-filter-empty-item>
                </div>
            }
            @if (fieldGuids) {
                <div class="p-0 w-full">
                    <app-tb-menu
                        class="w-full"
                        [template]="menuContent"
                        [class.disabled-menu-trigger]="formGroup.invalid || isEmpty"
                    >
                        <div
                            trigger
                            class="flex justify-between w-full"
                            *transloco="let tFilter; read: 'filter'"
                        >
                            <app-tb-button
                                [disabled]="formGroup.invalid || isEmpty"
                                [appearance]="'text'"
                                [icon]="'add_circle_outline'"
                            >
                                <span class="body-2">{{ tFilter('add_condition') }}</span>
                            </app-tb-button>
                            @if (!single) {
                                <app-tb-button
                                    [appearance]="'text'"
                                    [color]="'secondary'"
                                    (click)="deleteGroup.emit()"
                                >
                                    <span class="body-2 cursor-pointer">{{
                                        tFilter('delete_condition_group')
                                    }}</span>
                                </app-tb-button>
                            }
                        </div>
                    </app-tb-menu>
                    <ng-template #menuContent let-layout>
                        <app-tb-menu-list [containerLayout]="layout">
                            @for (fieldGuid of fieldGuids; track fieldGuid) {
                                <app-tb-menu-list-item
                                    (click)="$event.stopPropagation(); addFilterClick(fieldGuid)"
                                >
                                    <app-field-name
                                        [field]="getFieldByGuid(fieldGuid)"
                                        [folder]="selectedFolder"
                                        [showIcon]="false"
                                    ></app-field-name>
                                </app-tb-menu-list-item>
                            }
                        </app-tb-menu-list>
                    </ng-template>
                </div>
            }
        </div>
    </div>
    <ng-template #filterItem>
        <div class="rounded-lg bg-newNeutral2 p-2">
            <div class="flex items-center p-0 mb-4 w-full">
                <div class="subheading-1 text-newTextLight">{{ tFilter('where_label') }}</div>
                @if (isShowFilterCombinationToggle()) {
                    <div class="flex items-center">
                        <app-filter-combination-toggle
                            class="mx-4"
                            [value]="filterCombinationType"
                            [combinationTypes]="filterCombinationTypes"
                            (valueChange)="combinationTypeChange($event)"
                        ></app-filter-combination-toggle>
                        <div class="text-newTextLight">{{ tFilter('where_conditions_match') }}</div>
                    </div>
                }
            </div>
            @if (formGroup.value) {
                @for (formItem of formItems; track formItem; let itemIndex = $index) {
                    <app-filter-item
                        (deleteFilter)="deleteFilter(itemIndex)"
                        (deleteGroup)="deleteGroup.emit()"
                        [itemIndex]="itemIndex"
                        [groupIndex]="groupIndex"
                        [fieldTypes]="fieldTypes"
                        [fieldGuids]="fieldGuids"
                        [totalItems]="
                            formFilterService.getFilterGroupValues(this.groupIndex).length
                        "
                        [fields]="fields"
                        [selectedFolder]="selectedFolder"
                    ></app-filter-item>
                }
            }
        </div>
    </ng-template>
</div>
