import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { TbOutlineContainerComponent } from '@components-library/tb-outline-container/tb-outline-container.component'

@Injectable({
    providedIn: 'root',
})
export class CellEventHandlerService {
    private cellInstances = new Map<string, TbOutlineContainerComponent>()

    /* Cell that has either Active of Focused(without editControls) state */
    private selectedCell: string | null = null

    constructor(@Inject(DOCUMENT) private document: Document) {
        document.addEventListener(
            'click',
            (event) => {
                if (this.selectedCell) {
                    this.cellInstances.get(this.selectedCell)?.handleClickOutside(event)
                }
            },
            true,
        )
    }

    selectCell(guid: string | null) {
        if (this.selectedCell && guid !== this.selectedCell) {
            this.cellInstances.get(this.selectedCell)?.deselectContainer()
        }

        this.selectedCell = guid
    }

    deselectCell() {
        this.selectCell(null)
    }

    registerCell(guid: string, container: TbOutlineContainerComponent) {
        this.cellInstances.set(guid, container)
    }

    remove(guid: string) {
        this.cellInstances.delete(guid)
        if (guid === this.selectedCell) {
            this.deselectCell()
        }
    }
}
