<app-tb-menu-list *transloco="let tOptions; read: 'card_header.options'">
    <app-tb-menu-list-item (click)="duplicate()" icon="control_point_duplicate">
        <span>{{ tOptions('duplicate') }}</span>
    </app-tb-menu-list-item>
    <app-tb-menu-list-item (click)="favorite.emit()" icon="star">
        <span>{{ tOptions('favorite') }}</span>
    </app-tb-menu-list-item>
    <app-tb-menu-list-item (click)="share()" icon="share">
        <span>{{ tOptions('share') }}</span>
    </app-tb-menu-list-item>
    <app-tb-menu-list-item
        (click)="manage()"
        class="border-y-2 py-2 border-newNeutral3"
        icon="edit_note"
    >
        <span>{{ tOptions('manage') }}</span>
    </app-tb-menu-list-item>
    <app-tb-menu-list-item (click)="delete()" color="red-600">
        <app-tb-icon item-icon icon="custom_trash"></app-tb-icon>
        <span item-title>{{ tOptions('delete') }}</span>
    </app-tb-menu-list-item>
</app-tb-menu-list>
