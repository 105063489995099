<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded group">
    <app-tb-button
        (click)="openDialog()"
        [color]="getButtonColor(hasErrors)"
        class="hover:bg-newNeutral3 rounded-lg"
        [disabled]="hasErrors && !hasFilterErrors"
        appearance="text"
        icon="filter_list"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </app-tb-button>
    <ng-template #buttonInside>
        <div *transloco="let tFilter; read: 'filter'" class="flex items-center">
            <span class="body-2 mr-2">{{ tFilter('filter') }}</span>
            @if (hasFilterErrors) {
                <span
                    class="flex ml-2 caption-2 rounded-full items-center justify-center py-0 px-1 h-4 min-w-[1rem] text-white"
                >
                    <app-tb-icon
                        icon="error"
                        [filled]="true"
                        class="text-newErrorLight group-hover:text-newError"
                    ></app-tb-icon>
                </span>
            } @else if (filterGroups.length) {
                <span
                    class="flex items-center justify-center caption-2 rounded-full py-0 px-1 h-4 min-w-[1rem] bg-newSecondaryLight group-hover:bg-newSecondary text-white"
                >
                    {{ filterCountText }}
                </span>
            }
        </div>
    </ng-template>
</div>
