<div
    *transloco="let tManageFields; read: 'manage_fields'"
    class="flex xsm:flex-row items-center justify-between mb-2"
>
    <div
        class="grow flex items-center gap-4 pr-4 pl-2.5 py-2 rounded-lg shadow-md bg-newNeutral1 border-l-[0.375rem]"
        [style.border-color]="folder.color.value"
    >
        <div class="w-[calc(50%+0.5rem)] flex body-1">
            <span class="cursor-default">{{ field.name }}</span>
            <app-tb-tooltip-component [tooltip]="tManageFields('required_tooltip')">
                @if (field.is_required) {
                    <app-tb-icon
                        icon="asterisk"
                        color="newTextLight"
                        class="icon-xs cursor-pointer"
                    ></app-tb-icon>
                }
            </app-tb-tooltip-component>
            <app-tb-tooltip-component [tooltip]="tManageFields('on_top_tooltip')">
                @if (field.is_on_top) {
                    <app-tb-icon
                        icon="upgrade"
                        color="newTextLight"
                        class="icon-xs cursor-pointer"
                    ></app-tb-icon>
                }
            </app-tb-tooltip-component>
        </div>
        @if (field.folder_name?.name ?? tManageFields('unknown_folder'); as folderName) {
            <!--TODO: move to tooltip-->
            <app-tb-tooltip-component
                [tooltip]="folderName"
                [showOnlyOnOverflow]="true"
                class="w-1/4 caption-1"
            >
                <div class="rounded bg-newNeutral2 py-0.5 px-2 inline-block cursor-default">
                    <span
                        class="caption-1 text-ellipsis overflow-hidden whitespace-nowrap text-newTextLight"
                        >{{ folderName }}</span
                    >
                </div>
            </app-tb-tooltip-component>
        }
        <div class="flex items-center w-1/4 cursor-default">
            @if (fieldType?.icon; as icon) {
                <app-tb-icon [icon]="icon" color="newTextLight" class="icon-sm"> </app-tb-icon>
            }
            @if (fieldType?.name ?? tManageFields('unknown_type'); as type) {
                <app-tb-tooltip-component
                    [tooltip]="type"
                    [showOnlyOnOverflow]="true"
                    class="caption-1"
                >
                    <span
                        #typeBlock
                        class="text-ellipsis overflow-hidden whitespace-nowrap capitalize text-newTextLight"
                    >
                        {{ type }}
                    </span>
                </app-tb-tooltip-component>
            }
        </div>
    </div>
    <app-tb-menu [template]="fieldOptions" xPosition="after">
        <app-tb-button
            trigger
            appearance="icon"
            icon="more_vert"
            color="newPrimaryLight"
        ></app-tb-button>
    </app-tb-menu>
    <ng-template class="mb-2" #fieldOptions>
        <app-manage-field-field-options
            [field]="field"
            [selectedFolder]="folder"
            (deleteField)="deleteField.emit(field)"
        ></app-manage-field-field-options>
    </ng-template>
</div>
