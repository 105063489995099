<ng-container *transloco="let tFilter; read: 'filter'">
    <app-modal-layout
        [containerLayout]="containerLayout"
        (closeModal)="forceWithValidation()"
        (keydown.tab)="$event.stopPropagation()"
        [showLoader]="showLoader"
        [errors]="errors"
    >
        <ng-container title>
            <div class="text-newText">{{ tFilter('filters') }}</div>
        </ng-container>
        <div
            (click)="$event.stopPropagation()"
            class="max-sm:mt-4 min-w-[33.75rem] md:w-[57rem] h-[42rem]"
        >
            <div class="flex justify-end max-sm:pr-2">
                <span
                    (click)="clearAll()"
                    class="flex items-center cursor-pointer body-2 py-1 px-2"
                    [class]="isClearAll() ? 'text-newPrimary' : 'text-newNeutral4'"
                >
                    {{ tFilter('clear_all').toUpperCase() }}
                </span>
            </div>
            @if ((fields$ | async) || { fieldTypes: [], fields: {} }; as fieldData) {
                <div class="overflow-auto max-sm:pr-2 max-sm:mb-2">
                    @for (group of formGroups; track group; let groupIndex = $index) {
                        <app-filter-group
                            class="p-0"
                            (deleteGroup)="deleteGroup(groupIndex)"
                            (clearAll)="clearAll()"
                            [selectedFolder]="selectedFolder"
                            [fieldTypes]="fieldData.fieldTypes"
                            [fields]="fieldData.fields"
                            [groupIndex]="groupIndex"
                            [single]="formFilterService.getFilterFormArray.length === 1"
                            [isEmpty]="isFilterFormEmpty"
                        ></app-filter-group>
                    }
                    <div class="mt-0">
                        <app-tb-button
                            *transloco="let tFilter; read: 'filter'"
                            [appearance]="'text'"
                            [icon]="'add_circle_outline'"
                            [disabled]="!canAddFilterGroup"
                            (click)="addGroupAction($event)"
                            class="flex items-center w-fit"
                        >
                            <span class="body-2">{{ tFilter('add_group') }}</span>
                        </app-tb-button>
                    </div>
                </div>
            }
        </div>
        <ng-container buttons>
            <div *transloco="let tButtons; read: 'buttons'" class="flex justify-end items-center">
                <app-tb-button
                    appearance="outlined"
                    (click)="forceWithValidation()"
                    class="mr-4 whitespace-nowrap"
                >
                    <span class="body-2">{{ tButtons('cancel') }}</span>
                </app-tb-button>
                <div class="flex gap-1">
                    <app-tb-button appearance="primary" (click)="preview()">
                        {{ tButtons('preview') }}
                    </app-tb-button>
                    <app-tb-menu [template]="saveMenu" #tbMenu xPosition="before" yPosition="above">
                        <app-tb-button
                            trigger
                            appearance="iconPrimary"
                            [icon]="tbMenu.menuOpen ? 'expand_less' : 'expand_more'"
                        ></app-tb-button>
                    </app-tb-menu>
                </div>
                <ng-template #saveMenu>
                    <app-tb-menu-list class="w-[8.75rem] block">
                        <div class="ml-[-0.5rem] mr-[-0.5rem]">
                            <div class="mb-1">
                                <app-tb-menu-list-item (click)="resetToSave()" class="mb-1">{{
                                    tButtons('reset_to_saved')
                                }}</app-tb-menu-list-item>
                            </div>
                            <app-tb-menu-list-item (click)="resetToDefault()">{{
                                tButtons('reset_to_default')
                            }}</app-tb-menu-list-item>
                            <div class="px-2 my-2">
                                <app-tb-divider></app-tb-divider>
                            </div>
                            <app-tb-menu-list-item (click)="save()">{{
                                tButtons('save')
                            }}</app-tb-menu-list-item>
                        </div>
                    </app-tb-menu-list>
                </ng-template>
            </div>
        </ng-container>
    </app-modal-layout>
</ng-container>
