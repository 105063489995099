<div class="flex items-center w-full">
    @if (supportNewContainer) {
        <!-- TODO: remove valueChange after formControl behaviour will be fixed -->
        <app-input-cell-container
            [field]="field"
            [formControl]="fieldValueFormControl"
            [appearance]="inputAppearance"
            [hoverIcons]="hoverIcons"
            (valueChange)="cellValueChanged($event)"
        >
        </app-input-cell-container>
    } @else if (isFieldNonText) {
        @if (field.field_type_code !== FieldTypes.LINK) {
            <!-- TODO: https://chat.anveo.com/git/AnveoTikibase_ui/pulls/1270#issuecomment-26953 -->
            <app-cell-container
                [fieldTypes]="fieldTypes"
                [field]="field"
                [value]="fieldValueFormControl.value ?? ''"
                (cellValueChanged)="cellValueChanged($event)"
                [hoverIcons]="hoverIcons"
            ></app-cell-container>
        } @else {
            <mat-form-field appearance="outline" class="flex items-center w-full mb-2">
                <mat-select
                    multiple
                    [disabled]="fieldValueFormControl.disabled"
                    [value]="linkValue"
                    (selectionChange)="setLinkValue($event)"
                    placeholder="Select"
                >
                    @for (record of linkRecords; track record) {
                        <mat-option [value]="record.guid"> {{ record.name.value }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        <!-- TODO: will be reworked in task #1263 -->
    } @else {
        <app-tb-input
            [formControl]="fieldValueFormControl"
            (keydown.enter)="updateOnEnter()"
            class="w-full"
        ></app-tb-input>
    }
</div>
