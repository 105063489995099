<div class="flex flex-row justify-start items-center">
    @if (!disabled) {
        @for (reit of countStarsInArray; track reit) {
            <app-tb-icon
                icon="custom_star_full"
                color="newNeutral4"
                (click)="setRating(reit)"
                (mouseenter)="changeHoverStyles($event, reit)"
                (mouseleave)="backHoverStyles()"
                [class.hoverStar]="
                    (selectValue != undefined && reit <= selectValue) ||
                    (hoverValue != undefined && reit <= hoverValue)
                "
                class="star-item"
            >
            </app-tb-icon>
        }
    } @else {
        @for (reit of countStarsInArray; track reit) {
            <app-tb-icon
                icon="custom_star_full"
                color="newNeutral4"
                [class.hoverStar]="
                    (selectValue != undefined && reit <= selectValue) ||
                    (hoverValue != undefined && reit <= hoverValue)
                "
                class="star-item"
            >
            </app-tb-icon>
        }
    }
</div>
