import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { TbTooltipComponent } from '@components-library/tb-tooltip/tb-tooltip-component/tb-tooltip.component'
import { Field, Folder, getIconByFieldType } from '@core/models'
import { TbIconComponent } from '@components-library/tb-icon/tb-icon.component'

import { compact } from 'lodash-es'

@Component({
    selector: 'app-field-name',
    templateUrl: './field-name.component.html',
    styleUrls: ['./field-name.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TbIconComponent, TbTooltipComponent],
})
export class FieldNameComponent implements OnChanges {
    @Input()
    field!: Field

    @Input()
    folder?: Folder

    @Input()
    showIcon!: boolean

    @Input()
    iconColor?: string

    showFolderName = false

    ngOnChanges(changes: SimpleChanges) {
        if (changes.folder) {
            this.setShowFolderName()
        }
    }

    setShowFolderName() {
        if (this.folder?.guid && this.field?.folder_name?.name) {
            this.showFolderName = this.field.folder_guid !== this.folder.guid
            return
        }
        this.showFolderName = false
    }

    getFieldIcon() {
        return getIconByFieldType(this.field.field_type_code)
    }

    getTooltipText(): string {
        return compact([
            (this.showFolderName && this.field.folder_name!.name) || null,
            this.field.name || null,
        ]).join(' / ')
    }
}
