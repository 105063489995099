import { Injectable } from '@angular/core'
import { DataStorageService } from '@services/local-storage/view-settings-storage/data-storage.service'
import { ViewSettingsStorageService } from '@services/local-storage/view-settings-storage/view-settings-storage.service'

export interface CollapseState {
    [columnHeaderGuid: string]: {
        [groupValue: string]: boolean
    }
}

@Injectable({
    providedIn: 'root',
})
export class CollapseStateStorageService extends ViewSettingsStorageService<CollapseState> {
    constructor(dataStorageService: DataStorageService) {
        super(dataStorageService, 'collapse')
    }

    get(key: string): CollapseState | null {
        const configValue = super.get(key)

        if (!configValue) return null

        if (typeof configValue !== 'string') return configValue

        return configValue.length ? JSON.parse(configValue) : null
    }
}
