<div class="relative flex items-center max-sm:pl-4 max-sm:flex-wrap max-sm:mt-4 sm:mb-4">
    <div
        class="hidden max-sm:block absolute top-0 left-0 h-[calc(100%-16px)] w-[4px] bg-primaryLight rounded"
    ></div>
    <div class="flex items-center justify-between w-full">
        <div class="flex items-center w-[50%]">
            <div class="w-[60%] pr-2">
                <app-tb-select
                    class="flex-1 max-sm:mb-4 noHintError"
                    *transloco="let tFilter; read: 'filter'"
                    [placeholder]="tFilter('select_filter')"
                    [focusInitial]="true"
                    (selectionChange)="setFilterItem.emit($event)"
                >
                    @for (fieldGuid of fieldGuids; track fieldGuid) {
                        <app-tb-option [value]="fieldGuid">
                            <app-field-name
                                [field]="fields[fieldGuid]"
                                [folder]="selectedFolder"
                                [showIcon]="false"
                            ></app-field-name>
                        </app-tb-option>
                    }
                </app-tb-select>
            </div>
            <div class="w-[40%] pr-2">
                <app-tb-select [disabled]="true" class="max-sm:mb-4 noHintError"> </app-tb-select>
            </div>
        </div>
        <div class="w-[50%]">
            <div class="pr-6">
                <app-tb-input class="pointer-events-none"></app-tb-input>
            </div>
        </div>
    </div>
</div>
