<div class="sm:h-7 h-8 sm:bg-transparent bg-white rounded ml-4 group">
    <app-tb-button
        appearance="text"
        [color]="getButtonColor(hasGroupErrors)"
        icon="custom_group"
        class="hover:bg-newNeutral3 rounded-lg"
        [disabled]="getDisabledState(hasErrors, !hasGroupErrors)"
        (click)="openDialog()"
    >
        <ng-container *ngTemplateOutlet="buttonInside"></ng-container>
    </app-tb-button>
    <ng-template #buttonInside>
        <div *transloco="let tGroup; read: 'group'" class="rounded-lg body-2">
            <div class="flex items-center">
                <span class="flex items-center"
                    >{{ tGroup('group_by_btn') }}
                    @if (hasGroupErrors) {
                        <span
                            class="flex ml-2 caption-2 rounded-full items-center justify-center py-0 px-1 h-4 min-w-[1rem] text-white"
                        >
                            <app-tb-icon
                                icon="error"
                                [filled]="true"
                                class="text-newErrorLight group-hover:text-newError"
                            ></app-tb-icon>
                        </span>
                    } @else if (groupField) {
                        <span>:&nbsp;</span>
                        <app-field-name
                            [field]="groupField"
                            [folder]="selectedFolder"
                            [showIcon]="false"
                            class="group-name max-w-[160px]"
                        ></app-field-name>
                    }
                </span>
            </div>
        </div>
    </ng-template>
</div>
