import { hideViewLoader, showViewLoader } from '@core/@ngrx/ui/ui.actions'
import { createFeature, createReducer, on } from '@ngrx/store'
import { StoreFeatureNames } from '../store-feature-names'

const uiFeatureKey: string = StoreFeatureNames.UI

export interface UiState {
    viewLoader: boolean
}

const initialState: UiState = {
    viewLoader: false,
}

const uiReducer = createReducer(
    initialState,
    on(showViewLoader, (state: UiState) => {
        return {
            ...state,
            viewLoader: true,
        }
    }),
    on(hideViewLoader, (state: UiState) => {
        return {
            ...state,
            viewLoader: false,
        }
    }),
)

export const uiFeature = createFeature({
    name: uiFeatureKey,
    reducer: uiReducer,
})
