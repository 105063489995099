import { Injectable } from '@angular/core'
import { AbstractControl, AsyncValidator } from '@angular/forms'
import { SchemaFacadeService } from '@services/store-facade'
import { map, take } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class FieldExistsInSchemaValidator implements AsyncValidator {
    constructor(private readonly schemaFacade: SchemaFacadeService) {}

    validate = (control: AbstractControl) => {
        const fieldGuid = control.value

        return this.schemaFacade.selectSelectedTableSchemaFieldEntitiesFiltered$().pipe(
            take(1),
            map((fieldEntities) => {
                if (
                    !!fieldEntities &&
                    !!fieldGuid &&
                    !fieldEntities[fieldGuid] &&
                    !fieldGuid.includes('new')
                ) {
                    return {
                        invalidField: true,
                    }
                }

                return null
            }),
        )
    }
}
